import { gql } from '@apollo/client'

export const UPDATE_RECORD_TASK = gql`
  mutation UpdateRecordTask($recordInput: UpdateRecordInputType) {
    rasedUpdateRecord(recordInput: $recordInput) {
      record {
        mapData
        data
        id
        geometry
      }
    }
  }
`

export const CREATE_SIGNED_URL_TASK = gql`
  mutation CreateSignedUrlTask($orderId: Int!, $fileExtension: String!) {
    signedUrl(orderId: $orderId, fileExtension: $fileExtension) {
      blobUrl
      signedUrl
    }
  }
`

export const CREATE_RECORD_Task = gql`
  mutation CreateRecordTask($recordInput: UpdateRecordInputType!) {
    rasedUpdateRecord(recordInput: $recordInput) {
      record {
        id
        geometry
        data
        mapData
      }
    }
  }
`
export const DELETE_FIELD_RECORD = gql`
  mutation DeleteFieldRecord($recordInput: DeleteRecordInputType!) {
    deleteRasedRecord(recordInput: $recordInput) {
      status
    }
  }
`

export const CLOSE_TASK = gql`
  mutation closeRasedTask($taskInput: TaskStatusInputType!) {
    closeRasedTask(taskInput: $taskInput) {
      task {
        id
        status
      }
    }
  }
`

export const REJECT_TASK = gql`
  mutation rejectRasedTask($taskInput: TaskStatusInputType!) {
    rejectRasedTask(taskInput: $taskInput) {
      task {
        id
        status
      }
    }
  }
`
