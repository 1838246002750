import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  adornedEnd: {
    padding: '0 !important',
  },
  textfield: {
    fontSize: '12px',
    margin: '10px 0px',
    background: 'white',
  },
}))
