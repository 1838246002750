import { getMappedReducers } from '@flint/utils'

import { layersReducer } from './layers/layers'
import { layoutReducer } from './layout/layout'
import { tasksReducer } from './tasks'

export const plainReducers = getMappedReducers({
  prefix: '',
  reducers: {
    layout: layoutReducer,
    layers: layersReducer,
    tasks: tasksReducer,
  },
})
