import { DrawingTools, Record } from './layers'

export const updateLayersList = (payload: any[]) => {
  return {
    reducer: 'layers',
    type: 'layersList',
    payload,
  }
}

export const updateSelectedLayer = (payload: any) => {
  return {
    reducer: 'layers',
    type: 'selectedLayer',
    payload,
  }
}

export const updateSelectedRecord = (payload: Record) => {
  return {
    reducer: 'layers',
    type: 'selectedRecord',
    payload,
  }
}

export const resetWMSLayers = (payload: any) => {
  return {
    reducer: 'layers',
    type: 'resetWMSFlag',
    payload,
  }
}

export const updateUserLocationError = (payload: any) => {
  return {
    reducer: 'layers',
    type: 'userLocationError',
    payload,
  }
}
export const updateUserGeoLocation = (payload: any) => {
  return {
    reducer: 'layers',
    type: 'userLocationFlag',
    payload,
  }
}
export const updateDrawingTools = (payload: DrawingTools) => {
  return {
    reducer: 'layers',
    type: 'drawingTools',
    payload,
  }
}
export const toggleDrawnFeature = (payload: boolean) => {
  return {
    reducer: 'layers',
    type: 'drawnFeature',
    payload,
  }
}
export const toggleMultipleFeatureModal = (payload: boolean) => {
  return {
    reducer: 'layers',
    type: 'multipleFeaturesModal',
    payload,
  }
}
export const updateMultipleFeatureList = (payload: any[]) => {
  return {
    reducer: 'layers',
    type: 'multipleFeaturesList',
    payload,
  }
}
export const updatePoiSchemasList = (payload: any[]) => {
  return {
    reducer: 'layers',
    type: 'poiSchemasList',
    payload,
  }
}
export const updateSimpleProjectJsonSchema = (payload: any) => {
  return {
    reducer: 'layers',
    type: 'simpleProjectJsonSchema',
    payload,
  }
}
