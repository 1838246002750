import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from '@flint/locales'
import { updateSelectedFeature } from 'store/tasks'
import { useDispatch } from 'react-redux'
import {
  Divider,
  Dialog,
  DialogTitle,
  Button,
  ListItem,
  List,
} from '@material-ui/core'

interface FeatureChooserModalProps {
  open: boolean
  features: any[]
  onClose: () => void
}

export const FeatureChooserModal: React.FC<FeatureChooserModalProps> = ({
  open,
  features,
  onClose,
}) => {
  const [container, setContainer] = useState<HTMLElement | null>(null)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  useEffect(() => {
    // Create a div element for the portal and append it to the body
    const div = document.createElement('div')
    document.body.appendChild(div)
    setContainer(div)

    return () => {
      // Cleanup: Remove the div from the body when the component unmounts
      document.body.removeChild(div)
    }
  }, [])

  if (!container) {
    return null
  }

  return ReactDOM.createPortal(
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        style={{
          paddingBottom: 0,
          fontFamily: 'Almarai',
          fontSize: 'medium',
        }}
      >
        {t('Please choose a Feature')}
      </DialogTitle>

      <List>
        {features.map((feature, index) => (
          <div key={feature.id}>
            <ListItem>
              <Button
                onClick={() => {
                  dispatch(updateSelectedFeature(feature))
                  onClose()
                }}
              >
                {feature.id}
              </Button>
            </ListItem>
            {index !== features.length - 1 && <Divider />}
          </div>
        ))}
      </List>
    </Dialog>,
    container
  )
}
