import imageCompression, { Options } from 'browser-image-compression'
import { SourceLocation } from 'graphql'
import jsCookies from 'js-cookie'
import { baseMaps } from 'pages/Map/olMapLayers'

/**
 * Logs an error message to the console
 * @param type - The error type
 * @param message - The error message or error object
 * @param [locations] - optional array of source locations
 * @param [path] - optional array representing the path of the error
 */
export const logError = (
  type: 'GraphQlError' | 'NetworkError' | 'UnknownError' | string,
  message: string | Error,
  locations?: readonly SourceLocation[] | undefined,
  path?: readonly (string | number)[] | undefined
): void => {
  console.error(
    `%c[${type}] :`,
    'color:white;border-radius:3px;background:#ff4400;font-weight:bold;padding:2px 6px;',
    `message: ${message}, locations:${locations}, path: ${path}`
  )
}

/**
 * check if an object is empty
 * @param obj - The object to check against
 * @returns {boolean}
 */
export function isEmptyObject(obj) {
  return (
    obj &&
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  )
}

/**
 * get the user token stored in cookies
 * @returns {string} full user token for authentication
 */
export const getFullToken = (): string | null => {
  const token = jsCookies.get('access_token')
  return token ? `Bearer ${token}` : null
}

/**
 * Determines the truthiness of a value based on its type and content.
 *
 * @param value - The value to evaluate for truthiness.
 * @returns `true` if the value is truthy, `false` otherwise.
 */
export const isTruthy = (value: any): boolean => {
  if (typeof value === 'string') {
    return value.trim() !== ''
  } else if (Array.isArray(value)) {
    return value.length > 0
  } else if (typeof value === 'object') {
    return Object.keys(value).length > 0
  } else {
    return !!value
  }
}

export const getSelectedBaseMap = (label) =>
  baseMaps.find((basemap) => basemap.label === label)

export const isSimpleProject = location.pathname.includes('simple-project')

export const compressImg = async (file) => {
  const options: Options = {
    maxSizeMB: 1.5,
    maxWidthOrHeight: 1500,
    useWebWorker: true,
    preserveExif: true,
  }
  const compressedFile = (await imageCompression(file, options)) as any
  compressedFile.name = file.name
  return compressedFile
}

/**
 * Extracts the filename from a URL.
 * @param {string} url - The URL from which to extract the filename.
 * @returns {string} The extracted filename.
 */
export function extractFilename(url: string) {
  try {
    const urlObj = new URL(url, 'http://example.com')
    const pathname = urlObj.pathname
    return pathname.split('/').pop().split('#')[0].split('?')[0]
  } catch (error) {
    // If URL constructor fails, handle it as a simple path
    return url.split('/').pop().split('#')[0].split('?')[0]
  }
}
