import MUIDrawer from '@material-ui/core/Drawer'
import { DrawerProps } from './Drawer.interface'

export function Drawer({
  children,
  open,
  onClose,
  anchor,
  paperStyles,
}: DrawerProps) {
  return (
    <MUIDrawer
      anchor={anchor}
      open={open}
      onClose={onClose}
      PaperProps={{ style: paperStyles }}
    >
      {children}
    </MUIDrawer>
  )
}
