import { gql } from '@apollo/client'

export const CREATE_RECORD = gql`
  mutation CreateRecord($recordData: CreateGeometryRecordInput!) {
    createRecord(recordData: $recordData) {
      record {
        id
        data
        createdAt
        geometry
        updatedAt
      }
    }
  }
`

export const UPDATE_RECORD = gql`
  mutation UpdateRecord($recordData: UpdateGeometryRecordInput!) {
    updateRecord(recordData: $recordData) {
      record {
        id
        createdAt
        data
        geometry
        updatedAt
      }
    }
  }
`

export const UPLOAD_FILES = gql`
  mutation UploadFiles(
    $imageFile: Upload!
    $context: ImageUploadContext!
    $extraArgs: JsonData!
  ) {
    uploadImage(
      imageFileInput: {
        imageFile: $imageFile
        context: $context
        extraArgs: $extraArgs
      }
    ) {
      errors
      url
    }
  }
`

export const CREATE_SIGNED_URL = gql`
  mutation CreateSignedURL(
    $layerId: Int!
    $organizationId: Int!
    $fileExtension: String!
  ) {
    createRecordAttachmentSignedUrl(
      layerId: $layerId
      organizationId: $organizationId
      fileExtension: $fileExtension
    ) {
      blobUrl
      signedUrl
    }
  }
`

export const UPDATE_CLASSIFICATION_REPORT = gql`
  mutation updateClassificationReport($inputForm: ClassificationReportInput!) {
    updateClassificationReport(inputForm: $inputForm) {
      fieldSurveyImages {
        id
        created
        geom
        metadata: data
        order {
          id
          created
          modified
        }
        classificationTask {
          id
          layerName
          employee {
            id
            firstName
            lastName
            email
            phone
            avatar
          }
        }
        classificationReport {
          id
          created
          modified
          data
        }
      }
    }
  }
`

export const UPDATE_CLASSIFICATION_TASK_STATUS = gql`
  mutation completeClassificationTask(
    $inputForm: CompleteClassificationTaskInput!
  ) {
    completeClassificationTask(inputForm: $inputForm) {
      officeClassificationTask {
        id
        created
        modified
        status
        surveyImagesCount
        layerName
        employee {
          id
          firstName
          lastName
          email
          phone
          avatar
        }
        order {
          id
          created
          modified
        }
      }
    }
  }
`
