import { useDispatch, useSelector } from 'react-redux'
import Pagination from '@material-ui/lab/Pagination'
import { updateOffsetPage } from 'store/tasks'
import { RootState } from 'store'
import { PaginationItem } from '@material-ui/lab'
import { useStyle } from './SideListPagination.style'

const itemsPerPage = 10
const tasks = (state: RootState) => state.tasks

export const SideListPagination = () => {
  const classes = useStyle()
  const dispatch = useDispatch()

  const handlePageChange = (_, value: number) => {
    dispatch(updateOffsetPage((value - 1) * itemsPerPage))
  }

  const { offsetPage, tasksCount } = useSelector(tasks)
  const totalPages = Math.ceil(tasksCount / itemsPerPage) ?? 1
  const prevPage = Math.floor(offsetPage / itemsPerPage)

  return (
    <div className={classes.actionsWrapper}>
      <Pagination
        className={classes.paginationUl}
        count={totalPages}
        page={prevPage + 1}
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
        renderItem={(item) => (
          <PaginationItem
            {...item}
            className={item.selected ? classes.active : null}
          />
        )}
      />
    </div>
  )
}
