import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    top: `50%`,
    left: `50%`,
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    backgroundColor: '#F1F3F2',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  logoutButton: {
    fontSize: '15px',
    fontWeight: 700,
    color: '#B90000',
  },
}))
