import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(() => ({
  root: { padding: 0, margin: 0, border: 0 },
  mapSectionContainer: {
    padding: 0,
    boxShadow: 'none',
    border: '1px solid #DDDDDD',
    borderRadius: '5px',
    margin: '0 10px',

    '&.Mui-expanded': {
      margin: '0 10px',
    },
  },
  mapItemContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(5rem, 1fr))',
    gap: '1rem',
  },
  sectionTitle: {
    color: '#444',
    marginTop: '0',
    paddingLeft: '5px',
    fontWeight: 'bold',
  },
  mapSingleItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px solid #D3D3D3',
    borderRadius: '10px',
    cursor: 'pointer',
    padding: 0,
  },
  summary: { display: 'flex', flexGrow: 0.98 },
}))
