import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import { AssignIcon, PendingIcon } from 'icons'
import { useDispatch } from 'react-redux'
import { toggleLayersDrawerOpen, updateSelectedListItem } from 'store/layout'
import { formatListItem } from 'utils/tasks.utils'

export const StatusIcons = ({ item }) => {
  const dispatch = useDispatch()
  const { status } = item
  return (
    <div
      onClick={() => {
        dispatch(toggleLayersDrawerOpen(false))
        dispatch(updateSelectedListItem(formatListItem(item, 'task')))
      }}
      style={{ alignSelf: 'baseline' }}
    >
      {status.hasOwnProperty('Pending Assign') ? (
        <RadioButtonUncheckedIcon style={{ fontSize: '.7rem' }} />
      ) : status.hasOwnProperty('pending_review') ? (
        <PendingIcon />
      ) : status.hasOwnProperty('assigned') ? (
        <AssignIcon />
      ) : (
        ''
      )}
    </div>
  )
}
