import { Palette } from '@material-ui/core/styles/createPalette'
import { baseMaps } from 'pages/Map/olMapLayers'

export type TaskActionProps = {
  actionType: 'close' | 'reject'
  taskId: string
  onSuccess?: () => void
  onFail?: () => void
  gpxUrls?: string
}

export enum Path {
  HOME = '/',
  DASHBOARD = '/dashboard',
  CHARTS = '/charts',
  HOW_TO_USE = '/how-to-use',
  COLLECT = '/collect',
  PROJECTS = '/projects',
}

export enum Roles {
  ACCOUNT_MANAGERS = 'account manager',
  DATA_ENTRY = 'data entry',
  EVALUATOR = 'evaluator',
  INSPECTOR = 'inspector',
  REVIEWER = 'reviewer',
  APPROVER = 'approver',
}

export interface OrgSettingsPalette {
  light: Palette
  type: 'dark' | 'light'
}
export interface IOrganization {
  name: string
  meta: any
  id: number
  fullLogo: string
  abstractLogo: string
  userPermissions?: Array<string>
}

export interface CreateLayerRequest {
  name: string
  title: string
  description: string
  geometryColor: string
  mobileUiJsonSchema: string
  webUiJsonSchema: string
  geometryType: 'POINT' | 'POLYGON' | 'LINESTRING'
  jsonSchema: {
    properties: Array<{
      propKey: string
      title: string
      type: 'string' | 'number' | 'attachment' | 'date'
      isSummaryField: boolean
    }>
    required: Array<string>
  }
}

export enum PointTypes {
  POINT = 'POINT',
  POLYGON = 'POLYGON',
  LINESTRING = 'LINESTRING',
}

export interface IGeometry {}

export interface ILayer {
  id: number
  organization: IOrganization
  name: string
  title: string
  description: string
  geometryColor: string
  geometryType: PointTypes
  jsonSchema: Record<string, any>
  records: IRecord[]
  bboxRecords: IRecord[]
  webUiJsonSchema: any
  isGtLayer: boolean
  isClustered: boolean
  recordsCount: number
  userPermissions: LayerPermissions[]
  userRoles: Array<string>
}

export interface IRecord {
  id: number
  createdAt?: Date
  data: Record<string, any> | string
  properties: any
  geometry?: any
  updatedAt?: Date
  layer: ILayer
}

export interface IRecordResponse {
  recordsList: IRecord[]
}

export enum FormMode {
  VIEW = 'view',
  EDIT = 'edit',
  CREATE = 'create',
}

export enum UserPermissions {
  ADD_LAYER = 'add_layer',
  ADD_ORG = 'add_organization',
  ADD_RECORD = 'add_record',
  ADD_USER = 'add_user',
  CHANGE_ORG = 'change_organization',
  CHANGE_USER = 'change_user',
  DELETE_LAYER = 'delete_layer',
  DELETE_ORG = 'delete_organization',
  DELETE_RECORD = 'delete_record',
  DELETE_USER = 'delete_user',
  EDIT_LAYER = 'edit_layer',
  EDIT_RECORD = 'edit_record',
  VIEW_LAYER = 'view_layer',
  VIEW_ORG = 'view_organization',
  VIEW_RECORD = 'view_record',
  VIEW_USER = 'view_user',
}

export enum LayerPermissions {
  VIEW_USER = 'view_user',
  CHANGE_ORGANIZATION = 'change_organization',
  DELETE_USER = 'delete_user',
  ADD_RECORD = 'add_record',
  VIEW_RECORD = 'view_record',
  ADD_ORGANIZATION = 'add_organization',
  ADD_USER = 'add_user',
  VIEW_LAYER = 'view_layer',
  DELETE_LAYER = 'delete_layer',
  CHANGE_USER = 'change_user',
  DELETE_ORGANIZATION = 'delete_organization',
  EDIT_LAYER = 'edit_layer',
  VIEW_ORGANIZATION = 'view_organization',
  DELETE_RECORD = 'delete_record',
  ADD_LAYER = 'add_layer',
  EDIT_RECORD = 'edit_record',
  CHANGE_LAYER = 'change_layer',
}

export enum SchemaBuilderViews {
  UI_WEB_SCHEMA = 'UI_WEB_SCHEMA',
  UI_MOBILE_SCHEMA = 'UI_MOBILE_SCHEMA',
  CODE_EDITOR = 'CODE_EDITOR',
  SCHEMA = 'SCHEMA',
  UI_VIEW = 'UI_VIEW',
  PLAYGROUND = 'PLAYGROUND',
}

export enum DefaultOptions {
  RECORD_LIMIT = 50,
}

export enum EVENT_NAMES {
  PUSH_STATE = 'PUSH_STATE',
}

export interface PushStateArgs {
  currentURL: URL
  prevURL: URL
}

export interface RecordData {
  taskId: number
  geometry: string
  formData: string
}

export interface CreateRecordTaskProps {
  recordData: RecordData
  onSuccess?: () => void
  onFail?: () => void
}

export interface UpdateRecordTaskProps {
  recordId: number
  taskId: number
  formData: string
  onSuccess?: () => void
  onFail?: () => void
}

export interface DeleteFieldRecordProps {
  recordInput: {
    id: number
    taskId: number
  }
  onSuccess?: () => void
  onFail?: () => void
}

export enum TaskStatusEnum {
  PENDING_REVIEW = 'PENDING_REVIEW',
  ASSIGNED = 'ASSIGNED',
  DONE = 'DONE',
}
export interface TaskInputProps {
  taskInput: { id: number }
  onSuccess?: () => void
  onFail?: () => void
}

export type ProjectId = 'vd' | 'offers' | 'poi'

export type BaseMapLabel = (typeof baseMaps)[number]['label']

export type WmsLayerObj = {
  type: 'pa' | 'parcels' | 'points'
  name: string
}
