import React, { useEffect } from 'react'
import { TextField, InputAdornment, IconButton } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import {
  setSearchQuery,
  updateOffsetPage,
  updateSelectedCode,
} from 'store/tasks'

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { isTruthy } from 'utils'
import { useStyles } from './TaskSearchInput.style'

const selectTasks = (state: RootState) => state.tasks

export const TaskSearchInput: React.FC = () => {
  const dispatch = useDispatch()
  const { selectedCode: selectedTaskCode, searchQuery } =
    useSelector(selectTasks)
  const classes = useStyles()

  const handleSearchInputChange = (event) => {
    dispatch(setSearchQuery(event.target.value))
  }

  useEffect(() => {
    const searchInputTimeoutId = setTimeout(() => {
      dispatch(updateSelectedCode(searchQuery))

      // reset offset to 0 only when searching
      if (isTruthy(searchQuery)) {
        dispatch(updateOffsetPage(0))
      }
    }, 1000)
    return () => clearTimeout(searchInputTimeoutId)
  }, [searchQuery])

  return (
    <TextField
      className={classes.textfield}
      id="search-task"
      label="البحث عن مهمة"
      variant="outlined"
      size="small"
      fullWidth
      InputProps={{
        classes: {
          adornedEnd: classes.adornedEnd,
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              {searchQuery || selectedTaskCode ? (
                <ClearIcon
                  fontSize="small"
                  style={{ cursor: 'pointer', color: '#8E8E8E' }}
                  onClick={() => {
                    dispatch(setSearchQuery(''))
                    dispatch(updateSelectedCode(''))
                  }}
                />
              ) : (
                <SearchIcon style={{ cursor: 'pointer', color: '#8E8E8E' }} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      onChange={handleSearchInputChange}
      value={searchQuery ?? selectedTaskCode}
    />
  )
}
