import { useLoaderData } from 'react-router-dom'

/**
 * Custom hook to retrieve the user role information.
 * This hook utilizes the useLoaderData hook from react-router-dom to fetch user role data.
 * @returns {{ isSuperUser: boolean }} An object containing information about the user role, specifically whether the user is a super user.
 */
export const useUserRole = () => {
  const isSuperUser = useLoaderData() as { isSuperUser: boolean }
  return isSuperUser
}
