import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import { useDispatch } from 'react-redux'
import { SignOutIcon } from 'icons'
import { useAuth as useOidcAuth } from 'react-oidc-context'
import { useTranslation } from '@flint/locales'
import {
  fetchGlobalTaskCount,
  fetchUserAvatar,
} from 'store/layout/layout.async'
import { ProjectSwitcherHeader, ProjectSwitcherList } from 'components'
import { useStyles } from './ProjectSwitcher.style'

export const ProjectSwitcher = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { removeUser } = useOidcAuth()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(fetchGlobalTaskCount())
    dispatch(fetchUserAvatar())
  }, [])

  const logout = async () => {
    await removeUser()
    location.href = '/'
  }

  return (
    <div className={classes.paper}>
      <div>
        <ProjectSwitcherHeader />
        <ProjectSwitcherList />
      </div>
      <Button
        variant="text"
        className={classes.logoutButton}
        startIcon={<SignOutIcon style={{ width: '20px', height: '20px' }} />}
        onClick={logout}
      >
        {t('logout')}
      </Button>
    </div>
  )
}
