import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Map } from 'ol'
import { Layout } from 'shared'
import { MapComponent } from 'pages'
import { MapActions, DrawingTools, Basemaps, LayerRecordForm } from 'components'
import { LayersList } from 'components/Lists/LayersList'
import { RootState } from 'store'
import { fetchLayersList } from 'store/layers/layers.async'
import { FormDrawer, SideBarDrawer } from 'components/AppDrawers'

const layers = (state: RootState) => state.layers

export const Vd = () => {
  const dispatch = useDispatch()
  const mapRef = useRef<Map | null>(null)

  const { layersList } = useSelector(layers)

  // fetch layers data
  useEffect(() => {
    dispatch(fetchLayersList())
  }, [])

  return (
    <Layout>
      <MapComponent mapRef={mapRef} />
      <DrawingTools map={mapRef.current} />
      <FormDrawer form={<LayerRecordForm />} />

      <SideBarDrawer>
        <Basemaps />
        <LayersList layers={layersList} />
        <MapActions map={mapRef.current} />
      </SideBarDrawer>
    </Layout>
  )
}
