import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import toast from 'react-hot-toast'
import {
  Dialog,
  DialogTitle,
  Button,
  DialogActions,
  DialogContent,
  styled,
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { useTranslation } from '@flint/locales'
import { SimpleProjectService } from 'services/simpleProject.service'
import { logError } from 'utils'

interface UploadFileModalProps {
  open: boolean
  onClose: () => void
  onConfirmCb: (urls?: any[]) => Promise<void>
  taskId: string
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const simpleProjectService = new SimpleProjectService()

export const UploadFileModal: React.FC<UploadFileModalProps> = ({
  open,
  onClose,
  onConfirmCb,
  taskId,
}) => {
  const [container, setContainer] = useState<HTMLElement | null>(null)
  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState<{ key: string; file: File }[]>([])
  const { t } = useTranslation()
  useEffect(() => {
    const div = document.createElement('div')
    document.body.appendChild(div)
    setContainer(div)

    return () => {
      document.body.removeChild(div)
    }
  }, [])

  const handleOnClose = (evt, reason) => {
    if (reason && reason === 'backdropClick' && 'escapeKeyDown') return
    setFiles([])
    onClose()
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFiles(
        Array.from(event.target.files).map((f) => ({ key: f.name, file: f }))
      )
    }
  }

  const handleConfirm = async () => {
    if (!files.length) {
      toast.error('لا يوجد ملفات!')
      return
    }
    try {
      setLoading(true)
      const uploaded = await Promise.all(
        files.map((f) => simpleProjectService.uploadGpxFile(f.file, taskId))
      )
      const gpxUrls = uploaded.map((f) => f?.blobUrl)
      onClose()
      await onConfirmCb(gpxUrls)
      setLoading(false)
      setFiles([])
    } catch (err) {
      logError('creating gpx signed url', err)
    }
  }

  return container
    ? ReactDOM.createPortal(
        <Dialog
          style={{ textAlign: 'center' }}
          open={open}
          onClose={handleOnClose}
          container={() => container}
        >
          <DialogTitle>يرجى رفع ملفات gpx الخاصة بالمهمة</DialogTitle>
          <DialogContent>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              ارفع الملفات
              <VisuallyHiddenInput
                onChange={handleFileChange}
                type="file"
                multiple
                accept=".gpx"
              />
            </Button>
            <ul>
              {files.map((item) => (
                <li key={item.key}>{item.file.name}</li>
              ))}
            </ul>
            {loading && <CircularProgress />}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              {t('cancel')}
            </Button>
            <Button onClick={handleConfirm} color="primary" autoFocus>
              {t('confirm')}
            </Button>
          </DialogActions>
        </Dialog>,
        container
      )
    : null
}
