import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(() => ({
  taskTitle: {
    fontWeight: 700,
    color: '#555',
    fontFamily: 'Plus Jakarta Sans !important',
    fontSize: '14px',
  },
  activeTaskTitle: {
    fontWeight: 900,
    color: '#107543',
    fontSize: '15px',
    fontFamily: 'Plus Jakarta Sans !important',
  },
  teamCode: {
    color: '#999999',
    fontSize: '13px',
  },
}))
