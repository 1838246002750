import { NavigationPanaIcon, TatabaLogo } from 'icons'
import React from 'react'

import { useAuth } from '@flint/core'
import { useTranslation } from '@flint/locales'
import { Button, Grid, Link } from '@material-ui/core'

import { useStyle } from './Home.style'

export const HomePage = () => {
  const { loggedIn } = useAuth()
  const classes = useStyle()
  const { t } = useTranslation()

  const authenticate = () => {
    location.href = '/projects'
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Grid container className={classes.headerContainer}>
          <Grid item xs={12} className={classes.platformDescription}>
            <div className={classes.platformContent}>
              <TatabaLogo className={classes.logo} />
              <Grid item xs={12} className={classes.headerBannerContainer}>
                <NavigationPanaIcon className={classes.headerBanner} />
              </Grid>
              <p className={classes.headerDescription}>
                أحد منصات منظومة تتبع لإدارة عمليات الحشود, تتبع الراصد هي منصة
                مخصصة لعمليات الرصد الميداني الشامل والسريع والمدعمة بالبيانات
                المكانية والذكاء الاصطناعي وقدرات التوجيه وضبط الجودة.
              </p>
            </div>
          </Grid>
        </Grid>

        <Grid container className={classes.footerContainer}>
          <Grid item xs={12} className={classes.loginContainer}>
            <div className={classes.loginTitle}>
              {t('login to the platfrom')}
            </div>
          </Grid>
          <Grid item xs={12} className={classes.loginContainer}>
            <Button
              variant="contained"
              aria-label="login"
              disableElevation
              onClick={authenticate}
              className={classes.loginButton}
              disabled={loggedIn}
              color="primary"
            >
              {loggedIn ? 'Checking your account' : t('login')}
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.loginContainer}>
            <Button
              variant="contained"
              aria-label="register"
              disableElevation
              onClick={authenticate}
              className={classes.newRegisterButton}
              color="primary"
              disabled={loggedIn}
            >
              {t('new registration')}
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.loginContainer}>
            <Link href="/privacy" className={classes.termsOfUse}>
              {t('terms of use and privacy policy')}
            </Link>
          </Grid>
          <Grid item xs={12} className={classes.appLinkContainer}>
            <div className={classes.copyright}>{t('copyright')}</div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
