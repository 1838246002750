import GeoJSON from 'ol/format/GeoJSON'
import { gqlClient } from 'lib/graphql'
import { ListItemI } from 'store/layout/layout'
import { ProjectI } from 'store/tasks/tasks.interface'
import { GET_POI_RECORD, POI_FORM_SCHEMAS } from 'global/graphql/poi'
import { CHECK_GEOMETRY_VALIDITY, GET_RECORD_DATA, WmsLayerObj } from 'global'
import { store } from '@flint/core'
import { updatePoiSchemasList } from 'store/layers'
import { isTruthy } from './general.utils'

/**
 * Object containing URLs for different WMS layers.
 */
const WMS_URLS = {
  vd: process.env.REACT_APP_WMS_URL,
  offers: process.env.REACT_APP_WMS_URL_OFFERS,
  poi: process.env.REACT_APP_WMS_URL_POI,
}

/**
 * Retrieves the selected project based on the current URL path.
 * @returns The selected project.
 */
export const getSelectedProject = (): ProjectI =>
  location.pathname.split('/')[1] as ProjectI

/**
 * Formats an item into a ListItemI object.
 * @param item - The item to be formatted.
 * @param type - The type of the item ('layer' or 'task').
 * @returns The formatted ListItemI object.
 */
export const formatListItem = (
  item: any,
  type: 'layer' | 'task'
): ListItemI => {
  return {
    type,
    id: item?.id,
    title: type === 'layer' ? item?.title || item?.name : item?.code,
    wmsFeaturetypeName:
      type === 'layer'
        ? item?.wmsFeaturetypeName
        : item?.baseLayer?.wmsFeatureTypeName,
    jsonSchema: type === 'layer' ? item?.jsonSchema : item?.jsonschema?.form,
    webUiJsonSchema:
      type === 'layer' ? item?.webUiJsonSchema : item?.jsonschema?.webUISchema,
    userPermissions: type === 'layer' ? item?.userPermissions : '',
    layerKey: item?.dataLayer?.key,
    boundaries: item?.boundaries || item?.baseLayer?.boundaries,
    wmsDataLayerName: item?.dataLayer?.wmsFeatureTypeName,
    teamCode: item?.teamCode,

    // poi related
    paWmsName: item?.paWmsName,
    parcelsWmsName: item?.parcelsWmsName,
    pointsWmsName: item?.pointsWmsName,

    // simple project
    gpxUrls: item?.gpxUrls,
  }
}

/**
 * Retrieves WMS layer names for a given task and target map.
 * @param task - The task for which to retrieve WMS layer names.
 * @param targetMap - The target map ('vd', 'offers', or 'poi').
 * @returns An array of WmsLayerObj objects.
 */
export const getTaskWmsLayerNames = (
  task: ListItemI,
  targetMap: 'vd' | 'offers' | 'poi' | 'simple'
): WmsLayerObj[] => {
  const layers = []
  if (targetMap === 'poi') {
    layers.push({ type: 'pa', name: task?.paWmsName })
    layers.push({ type: 'parcels', name: task?.parcelsWmsName })
    layers.push({ type: 'points', name: task?.pointsWmsName })
  } else if (targetMap === 'simple') {
    layers.push({ type: 'pa', name: task?.paWmsName })
    layers.push({ type: 'points', name: task?.pointsWmsName })
  }

  return layers
}

/**
 * Retrieves the base URL for WMS requests based on the selected project.
 * @returns The base URL for WMS requests.
 */
export const retrieveWmsBaseUrl = (): string => {
  const currProject = getSelectedProject()
  return WMS_URLS[currProject] ?? process.env.REACT_APP_WMS_URL_SIMPLE
}

/**
 * Retrieves the extent of a polygon from its boundaries.
 * @param boundaries - The boundaries of the polygon.
 * @returns The extent of the polygon.
 */
export const getPolygonExtent = (boundaries): number[] => {
  const geoJsonFeature = new GeoJSON().readFeature(boundaries, {
    dataProjection: 'EPSG:4326',
    featureProjection: 'EPSG:3857',
  })
  const extent = geoJsonFeature.getGeometry().getExtent()
  return extent
}

/**
 * Checks the geometry of a task for validity.
 * @param geometry - The geometry to be checked.
 * @param taskId - The ID of the task.
 * @returns An object indicating whether the geometry is valid.
 */
export const checkTaskGeometry = async (
  geometry,
  taskId
): Promise<{ valid: boolean }> => {
  const { data } = await gqlClient.query({
    query: CHECK_GEOMETRY_VALIDITY,
    variables: { geometry, taskId },
  })
  return data.checkGeometry
}

/**
 * Retrieves data for a record associated with a task.
 * @param taskId - The ID of the task.
 * @param pk - The primary key of the record.
 * @returns The record data.
 */
export const getRecordData = async ({ taskId, pk }) => {
  // pk here refer to record id
  const { data } = await gqlClient.query({
    query: GET_RECORD_DATA,
    variables: { taskId, pk },
  })
  return data?.records
}

/**
 * Debounces a function call.
 * @param func - The function to be debounced.
 * @param delay - The delay in milliseconds.
 * @returns The debounced function.
 */
export const debounce = (func, delay) => {
  let timeoutId
  return ((...args) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      func(...args)
    }, delay)
  })()
}

/**
 * Builds dynamic GraphQL filters.
 * @param status - Task status to filter by.
 * @param code - Code to filter by.
 * @returns An array of filters to be used in GraphQL queries.
 */
export const buildFilters = ({ status, code }) => {
  const filters = []
  if (isTruthy(status)) {
    filters.push({
      field: 'status',
      value: status,
    })
  }
  if (isTruthy(code)) {
    filters.push({
      field: 'code',
      value: code,
      clause: 'icontains',
    })
  }
  return filters
}

/**
 * Retrieves the type of a POI.
 * @param taskId - The ID of the task.
 * @param pk - The primary key of the record.
 * @returns The type of the POI.
 */
export const getPoiType = async ({ taskId, pk }) => {
  // pk here refer to record id
  const { data } = await gqlClient.query({
    query: GET_POI_RECORD,
    variables: { taskId, pk },
  })
  return data?.records
}

/**
 * Retrieves a list of POI schemas.
 */
export const getPoiSchemasList = async () => {
  const { data } = await gqlClient.query({
    query: POI_FORM_SCHEMAS,
  })

  store?.dispatch(
    updatePoiSchemasList(data?.orders?.data[0]?.jsonSchema?.rased)
  )
}
