import { Dispatch } from 'react'
import { LayerServiceInstance } from 'services/layers.services'
import { ORGANIZATION_ID } from 'shared'
import { formatListItem, logError } from 'utils'
import { toggleDataLoading, updateSelectedListItem } from 'store/layout'
import { updateLayersList } from './layers.actions'

export const fetchLayersList = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(toggleDataLoading(true))
    try {
      const orgLayersList = await LayerServiceInstance.getLayersList(
        ORGANIZATION_ID,
        null,
        { limit: 300 }
      )
      const firstLayer = orgLayersList?.layersList?.[0] || null
      dispatch(updateSelectedListItem(formatListItem(firstLayer, 'layer')))
      dispatch(updateLayersList(orgLayersList?.layersList || []))
    } catch (error) {
      logError('GraphQlError', error)
    } finally {
      dispatch(toggleDataLoading(false))
    }
  }
}
