import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(() => ({
  root: {
    position: 'absolute',
    left: 5,
    bottom: 5,
    width: 45,
    height: 45,
    borderRadius: '14px',
  },
}))
