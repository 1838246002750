export function dataURItoBlob(dataURI: string) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1])

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  const blob = new Blob([ab], { type: mimeString })

  // @ts-ignore
  blob.name = `filename.${mimeString.split('/')[1]}`
  return blob
}

export const isDataURL = (value: string) => {
  if (typeof value !== 'string') return
  const isDataUrl = value.match(/^(data:.*\/.*;)/)
  const isImageURL = value.match(/^http.*\.(jpeg|jpg|gif|png)$/)
  return isDataUrl || isImageURL
}

export const getCurrentGql = () => {
  const currProject = location.pathname.split('/')[1]

  // const generalGql = process.env.REACT_APP_GENERAL_GQL
  const simpleProjectGQL = process.env.REACT_APP_SIMPLE_GQL
  const projectsGql = {
    vd: process.env.REACT_APP_ORGANIZATION_GQL,
    offers: process.env.REACT_APP_OFFERS_GQL,
    poi: process.env.REACT_APP_POI_GQL,
  }
  return projectsGql[currProject] ?? simpleProjectGQL
}

export const truncateFullName = (fullName) => {
  if (fullName.length > 20) {
    return `${fullName.slice(0, 19)}...`
  } else {
    return fullName
  }
}
