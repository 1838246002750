import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(() => ({
  layerItem: {
    padding: 4,
    paddingBottom: 10,
    marginTop: 10,
  },
  addBtn: {
    width: '160px',
    margin: '10px 5px',
  },
  layersContainer: {
    padding: '0 10px',
    height: '73%',
    flexGrow: 1,
    overflow: 'scroll',
    position: 'relative',
  },
  listWrapper: {
    marginBottom: '17vh',
  },
  title: {
    color: '#444',
    fontWeight: 'bolder',
    padding: 10,
  },
}))
