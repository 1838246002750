import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import LocationIcon from '@material-ui/icons/MyLocation'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import UndoIcon from '@material-ui/icons/Undo'
import SaveIcon from '@material-ui/icons/Save'
import { useTranslation } from '@flint/locales'
import { Menu, MenuItem } from '@material-ui/core'

import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useStyles } from './DrawingToolsBar.style'

const DrawingToolsBarItems = ({
  onActionClick,
  geomType,
  anchorEl,
  handleClick,
  handleClose,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { selectedListItem } = useSelector((state: RootState) => state.layout)

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbarContainer}>
        <IconButton value="close" color="primary" onClick={onActionClick}>
          <CloseIcon />
        </IconButton>
        <IconButton value="location" color="primary" onClick={onActionClick}>
          <LocationIcon />
        </IconButton>
        <IconButton value="delete" color="primary" onClick={onActionClick}>
          <DeleteIcon />
        </IconButton>
        <IconButton value="undo" color="primary" onClick={onActionClick}>
          <UndoIcon />
        </IconButton>
        <IconButton value="save" color="primary" onClick={onActionClick}>
          <SaveIcon />
        </IconButton>
        <IconButton
          aria-controls="geom-type-menu"
          aria-haspopup="true"
          onClick={handleClick}
          color="primary"
          size="small"
        >
          {t(geomType)}
          <ArrowDropDownIcon />
        </IconButton>
        <Menu
          id="geom-type-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem value="Point" onClick={onActionClick}>
            {t('Point')}
          </MenuItem>
          {selectedListItem?.type === 'task' ? (
            ''
          ) : (
            <>
              <MenuItem value="LineString" onClick={onActionClick}>
                {t('LineString')}
              </MenuItem>
              <MenuItem value="Polygon" onClick={onActionClick}>
                {t('Polygon')}
              </MenuItem>
            </>
          )}
        </Menu>
      </Toolbar>
    </AppBar>
  )
}

export default DrawingToolsBarItems
