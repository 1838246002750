import { makeStyles, createStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    toolbarContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    menuButton: {},
    title: {
      flexGrow: 1,
    },
    logoContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    iconBtn: { padding: '12px' },
    accountIcon: {
      padding: '12px 8px',
    },
    menuDrawerWrapper: {
      minWidth: '60vw',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      backgroundColor: '#F1F3F2',
      padding: theme.spacing(2, 4, 3),
    },
    userAvatar: {
      width: '35px',
      height: '35px',
      borderRadius: '50%',
    },
    accountName: {
      fontFamily: 'Almarai',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '13.39px',
      textAlign: 'right',
      marginLeft: '10px',
    },
  })
)
