import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import {
  Dialog,
  // DialogTitle,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import { useTranslation } from '@flint/locales'

interface ConfirmModalProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  //   title?: string
  content?: string
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  open,
  onClose,
  onConfirm,
  //   title = 'Confirm Action',
  content = 'are you sure from this action',
}) => {
  const [container, setContainer] = useState<HTMLElement | null>(null)
  const { t } = useTranslation()
  useEffect(() => {
    // Create a div element for the portal and append it to the body
    const div = document.createElement('div')
    document.body.appendChild(div)
    setContainer(div)

    return () => {
      // Cleanup: Remove the div from the body when the component unmounts
      document.body.removeChild(div)
    }
  }, [])

  return container
    ? ReactDOM.createPortal(
        <Dialog open={open} onClose={onClose} container={() => container}>
          {/* <DialogTitle>{title}</DialogTitle> */}
          <DialogContent>
            <DialogContentText>{t(content)}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              {t('cancel')}
            </Button>
            <Button onClick={onConfirm} color="primary" autoFocus>
              {t('confirm')}
            </Button>
          </DialogActions>
        </Dialog>,
        container
      )
    : null
}
