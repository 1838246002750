import { MutableRefObject, useEffect, useRef } from 'react'
import { Map } from 'ol'
import { useSelector, useDispatch } from 'react-redux'
import { FeatureChooserModal } from 'components/FeatureChooserModal'
import { toggleDataLoading } from 'store/layout'
import { toggleMultipleFeatureModal } from 'store/layers'
import { RootState } from 'store'
import { RasedMap } from './RasedMap'

const BaseMap = ({ mapRef }: { mapRef: MutableRefObject<RasedMap | Map> }) => {
  // map ref to be used in the DOM
  const domRef = useRef(null)
  const dispatch = useDispatch()
  const { multipleFeaturesList, multipleFeaturesModal } = useSelector(
    (state: RootState) => state.layers
  )

  useEffect(() => {
    if (mapRef.current) return
    mapRef.current = new RasedMap({
      target: domRef.current,
      enableTracking: true,
    })
  }, [])

  const handleCloseFeatureChooserModal = () => {
    dispatch(toggleMultipleFeatureModal(false))
    dispatch(toggleDataLoading(false))
  }

  return (
    <>
      <div
        ref={domRef}
        style={{
          width: '100%',
          height: 'calc(100vh - 55px)',
        }}
      />
      {multipleFeaturesModal && (
        <FeatureChooserModal
          open={multipleFeaturesModal}
          features={multipleFeaturesList}
          onClose={handleCloseFeatureChooserModal}
        />
      )}
    </>
  )
}

export default BaseMap
