import { USER_ROLE_CHECK } from 'global'
import { gqlClient } from 'lib/graphql'

export const userLoader = async (): Promise<{ isSuperUser: boolean }> => {
  const { data } = await gqlClient.query({
    query: USER_ROLE_CHECK,
  })
  const isSuperUser = data?.userDetails?.isSuperuser
  return { isSuperUser }
}
