import { useSelector } from 'react-redux'
import { Backdrop, CircularProgress } from '@material-ui/core'
import { RootState } from 'store'
import { useStyle } from './loading.style'

export const LoadingIndicator = () => {
  const { isDataLoading } = useSelector((state: RootState) => state.layout)
  const classes = useStyle()

  return (
    <Backdrop open={isDataLoading} className={classes.backdrop}>
      <CircularProgress color="secondary" />
    </Backdrop>
  )
}
