import React from 'react'
import { Outlet } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { Path } from 'global'

import { AuthenticationProvider, FlintReduxProvider } from '@flint/core'
import { DynamicGqlProvider } from 'lib/graphql'

function App() {
  return (
    <div className="App">
      <DynamicGqlProvider>
        <FlintReduxProvider>
          <AuthenticationProvider successUrl={Path.PROJECTS}>
            <Outlet />
          </AuthenticationProvider>
        </FlintReduxProvider>
      </DynamicGqlProvider>
    </div>
  )
}

export default Sentry.withProfiler(App)
