import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Map } from 'ol'
import AddLocationIcon from '@material-ui/icons/AddLocation'
import { RootState } from 'store'
import { updateDrawingTools, updateSelectedRecord } from 'store/layers'
import {
  HeaderSideList,
  DrawingTools,
  Basemaps,
  OffersRecordForm,
  MapFab,
  SideListPagination,
} from 'components'
import { TaskList } from 'components/Lists/TaskList'
import { fetchOffersTasks } from 'store/tasks/tasks.async'
import { Layout } from 'shared'
import { MapComponent } from 'pages'
import { buildFilters } from 'utils/tasks.utils'
import { FormDrawer, SideBarDrawer } from 'components/AppDrawers'

const layers = (state: RootState) => state.layers
const tasks = (state: RootState) => state.tasks

export const Offers = () => {
  const mapRef = useRef<Map | null>(null)
  const dispatch = useDispatch()

  const { drawingTools } = useSelector(layers)
  const { taskList, offsetPage, selectedStatusFilter, selectedCode } =
    useSelector(tasks)

  const { openFlag } = drawingTools

  // Fetch tasks list
  useEffect(() => {
    // build gql filters
    const filters = buildFilters({
      status: selectedStatusFilter,
      code: selectedCode,
    })
    dispatch(fetchOffersTasks({ filters, offset: offsetPage }))
  }, [offsetPage, selectedStatusFilter, selectedCode])

  return (
    <Layout>
      <MapComponent mapRef={mapRef} />
      <DrawingTools map={mapRef.current} />
      <FormDrawer form={<OffersRecordForm />} />

      <SideBarDrawer>
        <Basemaps />
        <HeaderSideList />
        <TaskList tasks={taskList} />
        <SideListPagination />
      </SideBarDrawer>

      {!openFlag && (
        <MapFab
          icon={<AddLocationIcon />}
          onClick={() => {
            dispatch(updateSelectedRecord({}))
            dispatch(updateDrawingTools({ ...drawingTools, openFlag: true }))
          }}
        />
      )}
    </Layout>
  )
}
