import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontWeight: 'bold',
    fontSize: theme.spacing(2.25),
    margin: theme.spacing(2, 0),
    color: theme.palette.primary.main,
  },
}))
