import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  projectsCard: {
    borderRadius: 8,
    border: '1px solid #E3E3E3',
    boxShadow: '0px 16px 32px -8px #D7D9D880',
    maxHeight: '50vh',
    overflowY: 'auto',
  },
  projectRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    paddingInline: '1rem',
    marginBlock: '1rem',
    paddingBlock: '1rem',
    borderBottom: '1px solid #F0F0F0',
  },

  taskCountText: {
    fontSize: '14px',
    fontWeight: 400,
  },
  titleCountwraperr: { textAlign: 'left' },
}))
