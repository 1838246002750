import ImageWMS from 'ol/source/ImageWMS'
import { retrieveWmsBaseUrl } from 'utils/tasks.utils'
import { getFullToken } from '@flint/auth'
import { ORGANIZATION_ID } from 'shared'

const wmsBaseUrl = retrieveWmsBaseUrl()
const baseUrl = new URL(process.env.REACT_APP_BASE_URL)
const scheme = baseUrl.protocol === 'http:' ? 'http' : 'https'

export function createWmsLayer({ wmsLayerName }: { wmsLayerName: string }) {
  if (!wmsLayerName) return
  const ongoingRequests = []
  const wmsSource = new ImageWMS({
    url: wmsBaseUrl,
    params: {
      LAYERS: [wmsLayerName],
      ORGANIZATION: ORGANIZATION_ID,
      FORMAT: 'image/vnd.jpeg-png8',
      FORMAT_OPTIONS: 'antialias:none',
      TIME: new Date().toISOString(),
    },
    imageLoadFunction: (image, src) => {
      const controller = new AbortController()
      ongoingRequests.forEach((request) => {
        request.controller.abort()
      })
      ongoingRequests.length = 0
      ongoingRequests.push({ controller })

      const headers = new Headers()
      headers.append('Authorization', getFullToken())

      fetch(src, {
        headers,
        method: 'GET',
        mode: 'cors',
        signal: controller?.signal,
      })
        .then((res) => {
          if (res.ok) {
            return res.blob()
          } else {
            throw new Error('Failed to load wms image.')
          }
        })
        .then((blob) => {
          let imgUrl = URL.createObjectURL(blob)
          if (scheme === 'https') {
            imgUrl = imgUrl.replace(/^http:/, 'https:')
          }
          const imageWrapper = image.getImage() as any
          imageWrapper.src = imgUrl
        })
        .catch((error) => {
          if (error.name === 'AbortError') {
            // eslint-disable-next-line no-console
            console.info('WMS Request canceled:', src)
          } else {
            // eslint-disable-next-line no-console
            console.error('Error loading wms image:', error)
          }
        })
    },
  })
  return wmsSource
}
