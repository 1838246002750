import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',

    fontSize: '1.4rem',
  },
  addphoto: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    fontSize: '1.2rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8rem',
    },
  },
  select: {
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
    fontSize: '1.4rem',
  },
  fileInputContainer: {
    background: '#eee',
    position: 'relative',
    width: theme.spacing(20),
    height: theme.spacing(20),
    border: '1px dashed #bbb',
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
    '&:hover': {
      background: 'rgba(228, 228, 228, 0.3)',
    },
  },
  fileInput: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    opacity: '0.000001',
    position: 'absolute',
  },
  uploadedFilesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  uploadedFileContainer: {
    background: '#000',
    width: theme.spacing(25),
    border: '1px solid #000',
    height: theme.spacing(20),
    borderRadius: theme.spacing(1),

    '&:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
    position: 'absolute',
  },
  uploadedFilePlaceholder: {
    width: theme.spacing(25),
    height: theme.spacing(20),
    margin: theme.spacing(4),
    marginRight: theme.spacing(1),
    '&:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  uploadedFile: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
  iconButtomWrapper: {
    position: 'absolute',
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.27)',
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.27)',
    },
  },
  icon: {
    margin: '0 10px',
  },

  hideInput: { display: 'none' },
  item: { fontSize: '1.4rem' },
  label: {
    fontWeight: 'bold',
    fontSize: theme.spacing(2.25),
    margin: theme.spacing(2, 0),
    color: theme.palette.primary.main,
  },
}))
