import React from 'react'
import ReactDOM from 'react-dom'
import { RouterProvider } from 'react-router-dom'
import { initClarity } from 'clarity'
import { router } from 'lib/routes'

import { initHotJarForProduction } from './hotjar'
import reportWebVitals from './reportWebVitals'
import { initSentryForProduction } from './sentry'
import './styles/main.scss'

// initialize sentry
initSentryForProduction()
// initialize clarity
initClarity()
// initialize hotjar
initHotJarForProduction()

ReactDOM.render(
  <RouterProvider router={router} />,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
