export type DrawingTools = {
  saveFlag: boolean
  openFlag: boolean
  deleteFlag: boolean
  undoFlag: boolean
  geomType: 'Point' | 'Polygon' | 'LineString'
}
export type Record = {
  id?: number
  geometry?: any
  wkt?: string
  data?: any
  layerId?: number
  paCode?: string
  poiType?: string
}
export interface ILayersState {
  // data state
  // layers and records
  layersList?: any[]
  selectedLayer?: any
  selectedRecord?: Record
  // ui
  userLocationFlag: boolean
  userLocationError?: any
  resetWMSFlag: boolean
  drawingTools: DrawingTools
  drawnFeature: boolean
  multipleFeaturesModal: boolean
  multipleFeaturesList: any
  poiSchemasList: any
  simpleProjectJsonSchema: any
}
export const layersReducer = (): ILayersState => ({
  layersList: [],
  selectedLayer: null,
  userLocationFlag: false,
  resetWMSFlag: false,
  drawingTools: {
    saveFlag: false,
    openFlag: false,
    deleteFlag: false,
    undoFlag: false,
    geomType: 'Point',
  },
  drawnFeature: false,
  multipleFeaturesModal: false,
  multipleFeaturesList: [],
  poiSchemasList: {},
  simpleProjectJsonSchema: {},
})
