import Fab from '@material-ui/core/Fab'
import LocationIcon from '@material-ui/icons/MyLocation'

import { useStyle } from './LocationFab.style'

export const LocationFab = ({ onClick }) => {
  const styles = useStyle()

  return (
    <Fab
      className={styles.root}
      color="secondary"
      variant="extended"
      aria-label="add"
      onClick={onClick}
    >
      <LocationIcon />
    </Fab>
  )
}
