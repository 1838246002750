import { SnackbarProps } from '@material-ui/core'
import { ListItemI } from './layout'

export const toggleSnackbar = (payload: SnackbarProps) => {
  return {
    reducer: 'layout',
    type: 'snackbar',
    payload,
  }
}

export const toggleLayersDrawerOpen = (payload: boolean) => {
  return {
    reducer: 'layout',
    type: 'layersDrawerOpen',
    payload,
  }
}

export const toggleFormDrawer = (payload: boolean) => {
  return {
    reducer: 'layout',
    type: 'formDrawerOpen',
    payload,
  }
}

export const toggleProjectSwitcherDrawer = (payload: boolean) => {
  return {
    reducer: 'layout',
    type: 'projectSwitcherDrawer',
    payload,
  }
}

export const updateErrorMessage = (payload: string) => {
  return {
    reducer: 'layout',
    type: 'errorMessage',
    payload,
  }
}

export const updateSelectedListItem = (payload: ListItemI) => ({
  reducer: 'layout',
  type: 'selectedListItem',
  payload,
})

export const updateUserRole = (payload: boolean) => {
  return {
    reducer: 'layout',
    type: 'isSuperuser',
    payload,
  }
}

export const updateSelectedBaseMap = (payload) => {
  return {
    reducer: 'layout',
    type: 'selectedBaseMapLabel',
    payload,
  }
}

export const toggleDataLoading = (payload: boolean) => {
  return {
    reducer: 'layout',
    type: 'isDataLoading',
    payload,
  }
}

export const setCountLoading = (loading: boolean) => ({
  reducer: 'layout',
  type: 'countLoading',
  payload: loading,
})

export const setGlobalTaskCount = (tasksCount: any) => ({
  reducer: 'layout',
  type: 'globalTaskCount',
  payload: tasksCount,
})
export const setUserAvatar = (avatar: any) => ({
  reducer: 'layout',
  type: 'userAvatar',
  payload: avatar,
})
