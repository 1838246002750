import { Dispatch } from 'react'
import { buildFilters, formatListItem, logError } from 'utils'
import { PoiTaskService } from 'services/poi.service'
import { gqlClient } from 'lib/graphql'
import { POI_FORM_SCHEMAS, GET_POI_RECORD } from 'global/graphql/poi'
import { updatePoiSchemasList, updateSelectedRecord } from 'store/layers'
import {
  toggleDataLoading,
  toggleFormDrawer,
  updateSelectedListItem,
} from 'store/layout'
import { RootState } from 'store/reducer'
import toast from 'react-hot-toast'
import {
  setSelectedRecordSchema,
  setTasksCount,
  updateTaskList,
} from './tasks.actions'

/**
 * Service instance for interacting with POI tasks.
 */
const taskClient = new PoiTaskService()

/**
 * Fetches POI tasks based on specified filters and offset.
 * @param filters - Filters to apply to the task list.
 * @param offset - Offset for pagination.
 * @returns A function that dispatches actions to update the task list.
 */
export const fetchPoiTasks = ({ filters, offset }) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(toggleDataLoading(true))
    try {
      const { data } = await taskClient.getTaskList(offset, filters)
      const tasksCount = data?.rasedTasks?.count
      const firstTask = data?.rasedTasks?.data[0] || null
      dispatch(setTasksCount(tasksCount))
      dispatch(updateTaskList(data?.rasedTasks?.data || []))
      dispatch(updateSelectedListItem(formatListItem(firstTask, 'task')))
    } catch (error) {
      logError('GraphQlError', error)
    } finally {
      dispatch(toggleDataLoading(false))
    }
  }
}

/**
 * Retrieves the list of POI schemas.
 * @returns A function that dispatches an action to update the POI schemas list.
 */
export const getPoiSchemasList = () => {
  return async (dispatch: Dispatch<any>) => {
    const { data } = await gqlClient.query({
      query: POI_FORM_SCHEMAS,
    })

    dispatch(
      updatePoiSchemasList(data?.orders?.data[0]?.jsonSchema?.rased || {})
    )
  }
}

/**
 * Sets the selected feature.
 * @returns A function that dispatches actions to update the selected feature and record.
 */
export const setSelectedFeature = () => {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(toggleDataLoading(true))
    try {
      const taskId = getState()?.layout?.selectedListItem?.id
      const selectedFeatureProps =
        getState()?.tasks?.selectedFeature?.properties

      const featData = await getPoiRecord({
        taskId,
        pk: selectedFeatureProps?.id,
      })

      const locationType = featData?.data[0]?.sourceProperties?.locationType
      const poiType = featData?.data[0]?.sourceProperties?.poiType
      const selectedFeatureSchemas = getState()?.layers?.poiSchemasList

      const newRecord = {
        id: selectedFeatureProps?.id,
        data: { ...featData?.data[0]?.data?.rased, locationType, poiType },
        layerId: selectedFeatureProps?.layer_id,
      }

      dispatch(updateSelectedRecord(newRecord))
      dispatch(
        setSelectedRecordSchema({
          jsonSchema: selectedFeatureSchemas?.form,
          webUiJsonSchema: selectedFeatureSchemas?.webUiJsonSchema,
        })
      )

      dispatch(toggleDataLoading(false))
      dispatch(toggleFormDrawer(true))
    } catch (error) {
      logError('GraphQlError', error)
    } finally {
      dispatch(toggleDataLoading(false))
    }
  }
}

/**
 * Retrieves a POI record.
 * @param taskId - The ID of the task.
 * @param pk - The primary key of the record.
 * @returns The POI record.
 */
export const getPoiRecord = async ({ taskId, pk }) => {
  // pk here refer to record id
  const { data } = await gqlClient.query({
    query: GET_POI_RECORD,
    variables: { taskId, pk },
  })
  return data?.records
}

export const takePoiTaskAction = ({
  id,
  actionType,
}: {
  id: string
  actionType: 'close' | 'reject'
}) => {
  return async (dispatch: Dispatch<any>, getState) => {
    try {
      await taskClient.takeAction({
        actionType,
        taskId: id,
        onSuccess: async () => {
          toast.success(
            actionType === 'close'
              ? 'تم إنهاء المهمة بنجاح'
              : 'تم رفض المهمة بنجاح',
            {
              duration: 1500,
            }
          )
          const { offsetPage, selectedStatusFilter, selectedCode } =
            getState()?.tasks ?? {}
          const filters = buildFilters({
            status: selectedStatusFilter,
            code: selectedCode,
          })
          dispatch(fetchPoiTasks({ filters, offset: offsetPage ?? 0 }))
        },
        onFail: () => {
          toast.error(
            actionType === 'close' ? 'فشل إنهاء المهمة ' : 'فشل رفض المهمة ',
            {
              duration: 1500,
            }
          )
        },
      })
    } catch (error) {
      logError('GraphQlError', error)
    }
  }
}
