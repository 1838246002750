import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { IconButton } from '@material-ui/core'
import { CloseIcon } from 'icons'
import { Drawer } from 'components/Drawer'
import { toggleProjectSwitcherDrawer } from 'store/layout'

export const ProjectSwitcherDrawer = ({ children }) => {
  const dispatch = useDispatch()
  const { projectSwitcherDrawer } = useSelector(
    (state: RootState) => state.layout
  )

  return (
    <Drawer
      anchor="left"
      onClose={() => {
        dispatch(toggleProjectSwitcherDrawer(false))
      }}
      open={!!projectSwitcherDrawer}
      paperStyles={{
        width: '100%',
        background: '#F1F3F2',
      }}
    >
      <IconButton
        onClick={() => dispatch(toggleProjectSwitcherDrawer(false))}
        style={{ alignSelf: 'flex-end', width: 'fit-content' }}
      >
        <CloseIcon />
      </IconButton>
      <div style={{ padding: '1rem' }}>{children}</div>
    </Drawer>
  )
}
