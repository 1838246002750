import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(() => ({
  layerList: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    maxHeight: '400px',
    minWidth: '180px',
    color: '#333',
    background: 'white',
    borderRadius: '.5rem',
    padding: '.5rem',
    margin: '3px 0',
  },
  label: {
    fontSize: '12px',
    fontWeight: 'bold',
  },
}))
