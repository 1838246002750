import { makeStyles, Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  mapWrapper: {
    position: 'relative',
  },
  dangerText: {
    color: theme.palette.error.main,
  },
  formWrapper: {},
  mapLayersIcon: {
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    top: 10,
    left: 10,
    zIndex: 405,
    color: '#5a5858',
    width: 50,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: theme.shadows[5],
    borderRadius: '50%',
  },
  hide: {
    display: 'none',
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  viewSectionItem: {
    margin: '10px 0',
  },
  viewSectionItemLabel: {
    marginBottom: '5px',
  },
  viewSectionImageBox: {
    width: '100%',
    height: 'max-content',
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    border: '1px solid lightgrey',
    borderRadius: '5px',
    padding: '5px 15px',
    maxWidth: '600px',
  },
  viewSectionImage: {
    maxWidth: '95%',
    margin: 'auto',
    cursor: 'pointer',
  },
  entrySection: {
    padding: '10px',
  },
  entrySectionFormSchemaContainer: {
    backgroundColor: '#fff',
    padding: theme.spacing(2.5),
    border: '1px solid #e1deed',
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(2),
  },
  actionsContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
  },
  actionButton: {
    fontSize: 12,
    fontWeight: 'bold',
    minHeight: theme.spacing(4.5),
    borderRadius: theme.spacing(1.25),
    '&:not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
}))
