import React, { useEffect, useState } from 'react'
import { useAuth as useOidcAuth } from 'react-oidc-context'
import {
  IconButton,
  Box,
  Menu,
  MenuItem,
  Divider,
  Typography,
} from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { TreeOutlinedIcon } from 'icons'
import { useTranslation } from '@flint/locales'
import { truncateFullName } from 'utils'
import { toggleProjectSwitcherDrawer } from 'store/layout'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { fetchUserAvatar } from 'store/layout/layout.async'
import { useStyles } from '../TopBar.style'

export const UserSection = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { userAvatar } = useSelector((rootState: RootState) => rootState.layout)
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { removeUser, user } = useOidcAuth()
  const open = Boolean(anchorEl)
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = async () => {
    setAnchorEl(null)
  }
  const logout = async () => {
    await removeUser()
    location.href = '/'
    handleClose()
  }
  const userFullName = `${user?.profile?.first_name} ${user?.profile?.last_name}`

  useEffect(() => {
    dispatch(fetchUserAvatar())
  }, [])

  return (
    <div>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box>
          <IconButton
            type="button"
            onClick={() => dispatch(toggleProjectSwitcherDrawer(true))}
            classes={{ root: classes.iconBtn }}
          >
            <TreeOutlinedIcon />
          </IconButton>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            classes={{ root: classes.accountIcon }}
          >
            {userAvatar ? (
              <img
                src={userAvatar}
                alt="avatar"
                className={classes.userAvatar}
              />
            ) : (
              <AccountCircle />
            )}
            <Typography className={classes.accountName}>
              {truncateFullName(userFullName)}
            </Typography>
          </IconButton>
        </Box>
      </Box>
      <Menu
        style={{ marginTop: 30 }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <div style={{ margin: 5 }}>{userFullName}</div>
        <Divider />
        <MenuItem style={{ fontSize: 14, color: 'red' }} onClick={logout}>
          {t('logout')}
        </MenuItem>
      </Menu>
    </div>
  )
}
