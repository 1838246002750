import toast from 'react-hot-toast'

export const processFile = (file: File) => {
  const { name, size, type } = file
  return new Promise((resolve, reject) => {
    if (!type.startsWith('image/')) {
      toast.error('يرجي اضافة صورة', {
        duration: 1500,
      })
      reject()
      return
    }
    const reader = new window.FileReader()
    reader.onerror = reject
    // eslint-disable-next-line no-unused-vars
    reader.onload = (_: ProgressEvent<FileReader>) => {
      resolve({
        // dataURL: addNameToDataURL(event.target?.result, name),
        name,
        size,
        file,
        type,
      })
    }
    reader.readAsDataURL(file)
  })
}
export const processFiles = (files: FileList): any => {
  return Promise.all([].map.call(files, processFile))
}
