export const replacedNames = [
  { regex: /pa/, replacement: 'منطقة التوجيه' },
  { regex: /points/, replacement: 'قطع الأراضي/ النقاط' },
  { regex: /gpx/, replacement: 'خط السير' },
]

export const getReplacedName = (name: string) => {
  return replacedNames.reduce(
    (acc, { regex, replacement }) => acc.replace(regex, replacement),
    name
  )
}
