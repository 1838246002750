import { gql } from '@apollo/client'

export const FETCH_TASK_LIST = gql`
  query FetchTaskList($offset: BoundedInt, $filters: [DjangoFilterInput!]) {
    rasedTasks(
      pageInfo: { limit: 10, offset: $offset, orderBy: "id" }
      filters: $filters
    ) {
      count
      data {
        id
        status
        code
        teamCode
        jsonschema
        dataLayer {
          wmsFeatureTypeName
          key
          boundaries
          id
        }
        baseLayer {
          wmsFeatureTypeName
          key
          boundaries
          id
        }
      }
    }
  }
`
export const FETCH_TASK_Count = gql`
  query FetchTaskCount($offset: BoundedInt, $filters: [DjangoFilterInput!]) {
    rasedTasks(
      pageInfo: { limit: 10, offset: $offset, orderBy: "id" }
      filters: $filters
    ) {
      count
    }
  }
`

export const GET_TASK_CODES = gql`
  query FetchTaskList {
    rasedTasks {
      data {
        code
      }
    }
  }
`
export const FETCH_TASK_DETAILS = gql`
  query FetchTaskDetails($pk: Int!) {
    tasks(taskType: FIELD, pk: $pk) {
      count
      data {
        id
        status
        code
        jsonSchema
        dataLayer {
          id
          description
          jsonSchema
          key
          metadata
          title
        }
      }
    }
  }
`

export const CHECK_GEOMETRY_VALIDITY = gql`
  query CheckGeometryValidity($geometry: String!, $taskId: Int!) {
    checkGeometry(geometry: $geometry, taskId: $taskId) {
      valid
    }
  }
`
export const GET_RECORD_DATA = gql`
  query GetReacordData($taskId: Int!, $pk: Int) {
    records(taskId: $taskId, pk: $pk) {
      data {
        data
        mapData
        id
        geometry
      }
    }
  }
`
