import { useEffect, useState } from 'react'
import LayersIcon from '@material-ui/icons/Layers'
import { IconButton } from '@material-ui/core'

import { LayerGroupVisibilityToggle } from 'components/LayerGroupVisibilityToggle'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useStyle } from './LayersControlPanel.style'

export const LayersControlPanel = ({ map }) => {
  const classes = useStyle()

  const [showLayersList, setShowLayersList] = useState<boolean>(false)
  const { selectedListItem } = useSelector((state: RootState) => state.layout)

  useEffect(() => {
    setShowLayersList(false)
  }, [selectedListItem])

  return (
    <div className={classes.root}>
      <div className={classes.mainControls}>
        <div style={{ position: 'relative' }}>
          <IconButton
            color="primary"
            size="medium"
            className={classes.layerIconBtn}
            onClick={() => setShowLayersList((v) => !v)}
          >
            <LayersIcon />
          </IconButton>
          {showLayersList && <LayerGroupVisibilityToggle map={map} />}
        </div>
      </div>
    </div>
  )
}
