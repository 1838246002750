/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'

import { Typography } from '@material-ui/core'
import { useStyles } from './SubTitle.styles'

export interface ISubTitleProps {
  subTitle: string
  hide?: boolean
  styles?: any
  tag?: string
}

export const SubTitle = ({ subTitle, styles, tag, hide }: ISubTitleProps) => {
  const classes = useStyles()
  const variant: any = tag || 'h3'

  return (
    <>
      {subTitle && !hide && (
        <Typography className={classes.root} style={styles} variant={variant}>
          {subTitle}
        </Typography>
      )}
    </>
  )
}
