import XYZ from 'ol/source/XYZ'
import OSM from 'ol/source/OSM'
import VectorSource from 'ol/source/Vector'
import { Vector } from 'ol/layer'
import TileLayer from 'ol/layer/Tile'
import GoogleMapIcon from 'images/gmap.png'
import OSMIcon from 'images/osm.png'
import SatelliteIcon from 'images/satellite.png'
import LayerGroup from 'ol/layer/Group'

export const OSMLayer = new TileLayer({ visible: false, source: new OSM() })
OSMLayer.set('name', 'OpenStreetMap')

export const defaultGoogle = new TileLayer({
  visible: false,
  source: new XYZ({
    url: 'https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}',
  }),
})
defaultGoogle.set('name', 'Google Maps Default')

export const satelliteGoogle = new TileLayer({
  visible: true,
  source: new XYZ({
    url: 'https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}',
  }),
})
satelliteGoogle.set('name', 'Google Maps Satellite')

export const baseMaps = [
  {
    label: 'satellite_google_map',
    icon: SatelliteIcon,
    map: satelliteGoogle,
    title: 'Satellite',
  },
  { label: 'osm', icon: OSMIcon, map: OSMLayer, title: 'OSM' },
  {
    label: 'google_map',
    icon: GoogleMapIcon,
    map: defaultGoogle,
    title: 'Google Map',
  },
]

export const baseMapsLayersGroup = [defaultGoogle, OSMLayer, satelliteGoogle]

export const baseMapsLayers = new LayerGroup({
  layers: baseMapsLayersGroup,
})
baseMapsLayers.set('name', 'BaseMapsLayers')

export const userLocation = new Vector({
  source: new VectorSource({ features: [] }),
})

export const User_Location_LAYER = 'user_location_layer'
userLocation.set('name', User_Location_LAYER)
