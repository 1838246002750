import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(() => ({
  drawerContentWrapper: {
    maxHeight: '50vh',
  },
  addBtn: {
    width: '160px',
    margin: '10px 5px',
  },
  actionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'fit-content',
    padding: '1rem',
    backgroundColor: 'rgb(241, 243, 242)',
    width: '100%',
    position: 'fixed',
    bottom: 0,
  },
  paginationUl: {
    '& .MuiPagination-ul': {
      flexWrap: 'nowrap',
    },
  },
  active: {
    '&.Mui-selected': {
      backgroundColor: 'black !important',
      color: 'white',
    },
  },
}))
