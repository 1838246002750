import React from 'react'
import { useSelector } from 'react-redux'
import { ElrasedLogo, AvatarPlaceholder } from 'icons'
import { RootState } from 'store'
import { useStyles } from './ProjectSwitcherHeader.style'

export const ProjectSwitcherHeader = () => {
  const classes = useStyles()
  const layout = (state: RootState) => state.layout

  const { userAvatar } = useSelector(layout)

  return (
    <div className={classes.logoWrapper}>
      <ElrasedLogo />
      {userAvatar ? (
        <img src={userAvatar} alt="avatar" className={classes.userAvatar} />
      ) : (
        <AvatarPlaceholder />
      )}
    </div>
  )
}
