import { Toaster } from 'react-hot-toast'
import { TopBar } from 'components'

export function Layout({ children }) {
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <TopBar />
      {children}
    </>
  )
}
