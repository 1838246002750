import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  HeaderSideList,
  Basemaps,
  SideListPagination,
  TaskList,
} from 'components'
import { Layout } from 'shared'
import { FormDrawer, SideBarDrawer } from 'components/AppDrawers'
import { buildFilters } from 'utils'
import { RootState } from 'store'
import { fetchPoiTasks, getPoiSchemasList } from 'store/tasks/poi.async'
import { PoiMap } from 'components/AppMaps/PoiMap'
import { PoiRecordForm } from 'components/RecordForm/PoiRecordForm'

const tasks = (state: RootState) => state.tasks

export const Poi = () => {
  const dispatch = useDispatch()

  const { taskList, offsetPage, selectedStatusFilter, selectedCode } =
    useSelector(tasks)

  // Get initial POI schemas list
  dispatch(getPoiSchemasList())

  // Fetch tasks list
  useEffect(() => {
    // build gql filters
    const filters = buildFilters({
      status: selectedStatusFilter,
      code: selectedCode,
    })
    dispatch(fetchPoiTasks({ filters, offset: offsetPage }))
  }, [offsetPage, selectedStatusFilter, selectedCode])

  return (
    <Layout>
      <FormDrawer form={<PoiRecordForm />} />

      <PoiMap />
      <SideBarDrawer>
        <Basemaps />
        <HeaderSideList />
        <TaskList tasks={taskList} />
        <SideListPagination />
      </SideBarDrawer>
    </Layout>
  )
}
