import { ITaskState } from './tasks.interface'

export const tasksReducer = (): ITaskState => ({
  taskList: [],
  selectedTask: null,
  selectedFeature: null,
  selectedStatusFilter: '',
  offsetPage: 0,
  selectedCode: '',
  searchQuery: '',
  tasksCount: 0,
  selectedRecordSchema: null,
})
