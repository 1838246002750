import { userLocation } from 'pages/Map/olMapLayers'
import Feature from 'ol/Feature'
import Geolocation from 'ol/Geolocation'
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style'
import { Point } from 'ol/geom'

export const positionFeature = new Feature()
positionFeature.setStyle([
  new Style({
    image: new CircleStyle({
      radius: 15,
      fill: new Fill({
        color: 'rgba(51, 153, 204, 0.5)',
      }),
    }),
  }),
  new Style({
    image: new CircleStyle({
      radius: 6,
      fill: new Fill({
        color: '#3399CC',
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 2,
      }),
    }),
  }),
])
let geolocation = null

export const moveToGPSLocation = ({ map, coordinates }) => {
  map?.getView()?.setCenter(coordinates)
  map?.getView()?.setZoom(20)
  positionFeature.setGeometry(new Point(coordinates))
}

export const startTracking = (map) => {
  if (!map) return

  const locationSource = userLocation.getSource()
  locationSource.removeFeature(positionFeature)
  locationSource.addFeature(positionFeature)

  if (!geolocation) {
    geolocation = new Geolocation({
      trackingOptions: {
        enableHighAccuracy: true,
      },
      projection: map?.getView()?.getProjection(),
    })

    geolocation.setTracking(true)

    geolocation.once('change:position', () => {
      const coordinates = geolocation?.getPosition()
      moveToGPSLocation({ map, coordinates })
    })
  } else {
    geolocation.setTracking(true)
    const coordinates = geolocation?.getPosition()
    moveToGPSLocation({ map, coordinates })
  }
}
