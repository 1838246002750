import { gql } from '@apollo/client'

export const FETCH_LAYERS = gql`
  query FetchLayers(
    $orgId: Int!
    $filters: LayersFiltersInputType
    $pageInfo: LayersPageInfoInputType
  ) {
    layersList(orgId: $orgId, filters: $filters, pageInfo: $pageInfo) {
      id
      description
      esIndexName
      wmsFeaturetypeName
      webUiJsonSchema
      userRoles
      userPermissions
      title
      status
      recordsCount
      name
      mobileUiJsonSchema
      metaData
      jsonSchema
      geometryColor
    }
  }
`

export const FETCH_RECORDS = gql`
  query FetchRecords(
    $layerId: Int!
    $filters: LookupsInputType
    $pageInfo: PageInfoInputType
  ) {
    recordsList(layerId: $layerId, filters: $filters, pageInfo: $pageInfo) {
      id
      createdAt
      data
      geometry
      updatedAt
      layer {
        geometryColor
        id
        jsonSchema
        metaData
        mobileUiJsonSchema
        name
        recordsCount
        status
        title
        userPermissions
        userRoles
        webUiJsonSchema
        wmsFeaturetypeName
        description
        esIndexName
      }
    }
  }
`

export const LAYER_DETAILS = gql`
  query LayerDetails($layerId: Int!) {
    layerDetails(layerId: $layerId) {
      description
      esIndexName
      geometryColor
      geometryType
      id
      jsonSchema
      metaData
      mobileUiJsonSchema
      name
      recordsCount
      title
      webUiJsonSchema
      wmsFeaturetypeName
    }
  }
`

export const CLASSIFICATION_TASKS = gql`
  query classificationTasksList($pk: Int, $pageInfo: PageInfo) {
    count: classificationTasksCount
    classificationTasks(pk: $pk, pageInfo: $pageInfo) {
      id
      created
      modified
      status
      surveyImagesCount
      layerName
      employee {
        id
        firstName
        lastName
        email
        phone
        avatar
      }
      order {
        id
        created
        modified
      }
    }
  }
`

export const FIELD_SURVEY_IMAGES = gql`
  query fieldSurveyImagesList(
    $classificationTaskPk: Int!
    $pageInfo: PageInfo
  ) {
    fieldSurveyImages(
      classificationTaskPk: $classificationTaskPk
      pageInfo: $pageInfo
    ) {
      id
      created
      geom
      metadata: data
      order {
        id
        created
        modified
      }
      classificationTask {
        id
        layerName
        employee {
          id
          firstName
          lastName
          email
          phone
          avatar
        }
      }
      classificationReport {
        id
        created
        modified
        data
      }
    }
  }
`

export const FORM_SCHEMAS = gql`
  query fromSchemas {
    layers {
      key
      jsonSchema
    }
  }
`

export const USER_ROLE_CHECK = gql`
  query getUserRole {
    userDetails {
      isSuperuser
    }
  }
`
export const USER_AVATAR = gql`
  query getUserAvatar {
    userDetails {
      avatar
    }
  }
`

export const TASKS_COUNT = gql`
  query getTasksCount {
    userProjectTasks {
      projects {
        project {
          name
          field {
            count
          }
        }
      }
    }
  }
`
export const GET_PROJECT_LIST = gql`
  query GetProjectList {
    projects {
      code
      created
      id
      modified
      name
    }
  }
`
