import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(() => ({
  cancelBtn: {
    border: 0,
    margin: '0 .4rem',
    color: '#B90000',
    padding: '1px  10px',
    fontSize: '10px',
    fontWeight: 700,
  },
  endBtn: {
    padding: '1px  10px',
    border: '1px solid #DDDDDD',
    background: '#E1E1E1',
    color: '#2C2C2C',
    fontSize: '10px',
    fontWeight: 700,
    width: '89px',
    height: '31px',
  },
}))
