import { Dispatch } from 'react'
import toast from 'react-hot-toast'
import { buildFilters, formatListItem, logError } from 'utils'
import { OffersTaskService } from 'services/offers.service'
import { toggleDataLoading, updateSelectedListItem } from 'store/layout'
import { setTasksCount, updateTaskList } from './tasks.actions'

const taskClient = new OffersTaskService()

export const fetchOffersTasks = ({ filters, offset }) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(toggleDataLoading(true))
    try {
      const { data } = await taskClient.getTaskList(offset, filters)
      const tasksCount = data?.rasedTasks?.count
      const firstTask = data?.rasedTasks?.data[0] || null
      dispatch(setTasksCount(tasksCount))
      dispatch(updateTaskList(data?.rasedTasks?.data || []))
      dispatch(updateSelectedListItem(formatListItem(firstTask, 'task')))
    } catch (error) {
      logError('GraphQlError', error)
    } finally {
      dispatch(toggleDataLoading(false))
    }
  }
}

export const takeTaskAction = ({
  id,
  actionType,
}: {
  id: string
  actionType: 'close' | 'reject'
}) => {
  return async (dispatch: Dispatch<any>, getState) => {
    try {
      await taskClient.takeAction({
        actionType,
        taskId: id,
        onSuccess: async () => {
          toast.success(
            actionType === 'close'
              ? 'تم إنهاء المهمة بنجاح'
              : 'تم رفض المهمة بنجاح',
            {
              duration: 1500,
            }
          )
          const { offsetPage, selectedStatusFilter, selectedCode } =
            getState()?.tasks ?? {}
          const filters = buildFilters({
            status: selectedStatusFilter,
            code: selectedCode,
          })
          dispatch(fetchOffersTasks({ filters, offset: offsetPage ?? 0 }))
        },
        onFail: () => {
          toast.error(
            actionType === 'close' ? 'فشل إنهاء المهمة ' : 'فشل رفض المهمة ',
            {
              duration: 1500,
            }
          )
        },
      })
    } catch (error) {
      logError('GraphQlError', error)
    }
  }
}
