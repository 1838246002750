import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'

export const FullscreenImageDialog = ({ open, onClose, imageUrl }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl">
      <DialogContent>
        <img
          src={imageUrl}
          alt="fullscreenImage"
          style={{
            width: '100%',
            height: 'auto',
            maxHeight: '100vh',
            maxWidth: '100vw',
            objectFit: 'contain',
          }}
        />
      </DialogContent>
    </Dialog>
  )
}
