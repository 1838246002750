import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(() => ({
  label: {
    fontSize: '9px',
    height: '22px',
  },
  btn: {
    padding: 2,
    flexGrow: 1,
    border: '1px solid #DDDDDD',
    width: '124.67px',
    height: '32px',
  },
  btnAll: {
    backgroundColor: '#2C2C2C',
    color: 'white',
    border: 0,
  },
  btnAssign: {
    borderColor: '#686868',
    color: '#686868',
  },
  btnAssignActive: {
    backgroundColor: '#686868 !important',
    color: 'white',
    border: 0,
    fontWeight: 'bold',
  },
  btnPending: {
    borderColor: '#FF892E',
    color: '#FF892E',
  },
  btnPendingActive: {
    backgroundColor: '#ff892e !important',
    color: 'white',
    border: 0,
    fontWeight: 'bold',
  },
  icon: {
    margin: '1px 6px 0px 6px',
  },
}))
