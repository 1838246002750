import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: '10px',
  },
  mainControls: {
    display: 'flex',
    flexDirection: 'row',
  },
  layerIconBtn: {
    background: 'white',
    padding: '.2rem',
    borderRadius: '10px',
    '&:hover': {
      background: 'white',
    },
  },
}))
