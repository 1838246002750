import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from '@flint/locales'
import { Button } from '@material-ui/core'
import MyLocationIcon from '@material-ui/icons/MyLocation'
import CreateIcon from '@material-ui/icons/Create'
import { toggleLayersDrawerOpen } from 'store/layout'
import { updateDrawingTools, updateSelectedRecord } from 'store/layers'
import { startTracking } from 'utils/trackCurrentPosition'
import { RootState } from 'store'
import { useStyle } from './MapActions.style'

// Redux selectors
const layers = (state: RootState) => state.layers

export const MapActions = ({ map }) => {
  const { drawingTools } = useSelector(layers)
  const dispatch = useDispatch()

  // Translation hook
  const { t } = useTranslation()
  // Styles
  const classes = useStyle()

  return (
    <div className={classes.actionsWrapper}>
      <Button
        onClick={() => {
          dispatch(updateSelectedRecord({}))
          dispatch(updateDrawingTools({ ...drawingTools, openFlag: true }))
          dispatch(toggleLayersDrawerOpen(false))
        }}
        variant="contained"
        color="primary"
        size="small"
        className={classes.addBtn}
        endIcon={<CreateIcon />}
      >
        {t('drawing_tools')}
      </Button>
      <Button
        onClick={() => {
          dispatch(toggleLayersDrawerOpen(false))
          startTracking(map)
        }}
        color="primary"
        size="small"
        className={classes.addBtn}
        endIcon={<MyLocationIcon />}
      >
        {t('locate_me')}
      </Button>
    </div>
  )
}
