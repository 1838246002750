import { useSelector } from 'react-redux'
import { useTranslation } from '@flint/locales'
import {
  ListItemContent,
  LoadingIndicator,
  StatusIcons,
  TaskStatusButtons,
} from 'components'
import { ListItem, Typography } from '@material-ui/core'
import { useUserRole } from 'hooks/useUserRole'
import { RootState } from 'store'
import { useStyle } from './List.style'

export const TaskList = ({ tasks }) => {
  const classes = useStyle()
  const { t } = useTranslation()
  const { isSuperUser } = useUserRole()
  const { isDataLoading } = useSelector((state: RootState) => state.layout)

  return (
    <div className={classes.layersContainer}>
      {isDataLoading ? (
        <LoadingIndicator />
      ) : (
        <div className={classes.listWrapper}>
          {tasks.length > 0 ? (
            tasks.map((task) => (
              <ListItem className={classes.layerItem} button key={task.id}>
                {isSuperUser && <StatusIcons item={task} />}
                <ListItemContent item={task} title="task" />
                <TaskStatusButtons task={task} />
              </ListItem>
            ))
          ) : (
            <div>
              <Typography> {t('no_tasks')} </Typography>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
