import { graphql } from 'global'
import { dataURItoBlob } from 'utils/common'

import { uploadFilesAndGetFinalData } from '@flint/utils'
import { gqlClient } from 'lib/graphql'

const {
  FETCH_LAYERS,
  CREATE_RECORD,
  UPDATE_RECORD,
  LAYER_DETAILS,
  UPLOAD_FILES,
  CREATE_SIGNED_URL,
} = graphql

export async function uploadToSignedURL(signedUrl: string, file: File) {
  try {
    const _headers = new Headers()
    _headers.append('Content-Type', 'application/octet-stream')
    const requestOptions = {
      method: 'PUT',
      headers: _headers,
      body: file,
    }
    const response = await fetch(signedUrl, requestOptions as any)
    if (!response.ok) {
      return {
        error: `Failed to upload: ${response.status} ${response.statusText}`,
      }
    }
    return { error: false }
  } catch (error) {
    return { error }
  }
}

export class LayerService {
  async getLayersList(orgId: number, filters: any = {}, pageInfo: any = null) {
    const { data } = await gqlClient.query({
      query: FETCH_LAYERS as any,
      variables: {
        orgId,
        filters,
        pageInfo,
      },
    })
    return data
  }

  async createRecord(recordData: any) {
    await gqlClient.mutate({
      mutation: CREATE_RECORD as any,
      variables: { recordData },
    })
  }

  async updateRecord(recordData: any) {
    const { data } = await gqlClient.mutate({
      mutation: UPDATE_RECORD as any,
      variables: { recordData },
    })
    const { updateRecord } = data
    return updateRecord.record
  }

  async fetchLayerDetails(layerId: number) {
    const { data } = await gqlClient.query({
      query: LAYER_DETAILS as any,
      variables: { layerId },
    })
    return data.layerDetails
  }

  async uploadFile(file: File | Blob, orgId: number, layerId: number) {
    const { data } = await gqlClient.mutate({
      mutation: UPLOAD_FILES as any,
      variables: {
        context: 'record_field_image',
        // extraArgs: `{"organizationId":${orgId}, "layerId":${layerId}}`,
        extraArgs: JSON.stringify({ layerId, organizationId: orgId }),
        imageFile: file,
      },
    })
    return data.uploadImage.url
  }

  async createSignedURL(
    fileExtension: string,
    organizationId: number,
    layerId: number
  ) {
    try {
      const { data, errors } = await gqlClient.mutate({
        mutation: CREATE_SIGNED_URL as any,
        variables: { layerId, organizationId, fileExtension },
      })
      const { createRecordAttachmentSignedUrl } = data
      const { blobUrl, signedUrl } = createRecordAttachmentSignedUrl ?? {}
      return { blobUrl, signedUrl, errors }
    } catch (error) {
      console.error(
        'Backend Error: while trying to generate signed url: ',
        error
      )
      return { blobUrl: null, signedUrl: null, errors: error }
    }
  }

  async uploadViaSignedUrl(
    file: File,
    organizationId: number,
    layerId: number,
    fallBack?: (
      file: File,
      organizationId: number,
      layerId: number
    ) => Promise<any>
  ) {
    // request signed url from the backend
    const fileExtension = file.name.split('.').pop()
    const { blobUrl, signedUrl, errors } = await this.createSignedURL(
      fileExtension,
      organizationId,
      layerId
    )
    if (errors) {
      console.error('Error obtaining signed url', errors)
      // try to upload via backend server graphql
      if (fallBack) return fallBack(file, organizationId, layerId)
      else throw errors
    }
    // upload the actual file to the signed url
    const { error } = await uploadToSignedURL(signedUrl, file)
    if (error) {
      console.error('Error uploading using signed url', error)
      // try to upload via backend server graphql
      if (fallBack) return fallBack(file, organizationId, layerId)
      else throw error
    }
    return blobUrl
  }

  async uploadFileAndUpdateData(formData: any, orgId: number, layerId: number) {
    return uploadFilesAndGetFinalData(formData, async (dataURL: any) => {
      // data URL TO file
      const file = dataURItoBlob(dataURL)
      return this.uploadFile(file, orgId, layerId)
    })
  }
}

export const LayerServiceInstance = new LayerService()
