import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  logoWrapper: {
    textAlign: 'center',
    marginTop: 20,
    marginBottom: '2rem',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2rem',
  },
  userAvatar: {
    width: '74px',
    height: '74px',
    borderRadius: '50%',
  },
}))
