import { getFullToken } from '@flint/auth'
import { setContext } from '@apollo/client/link/context'
import { logError } from 'utils/general.utils'

export const authLink = setContext((_, { headers }) => {
  try {
    const token = getFullToken()
    return {
      headers: {
        ...headers,
        Authorization: token,
        'Accept-Language': 'ar-AR',
      },
    }
  } catch (error) {
    logError('AuthError', error)
  }
})
