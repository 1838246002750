import Feature from 'ol/Feature'
import GeoJSON from 'ol/format/GeoJSON'
import { Geometry } from 'ol/geom'
import { Vector as VectorLayer } from 'ol/layer'
import { Vector as VectorSource } from 'ol/source'
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style'
import { WMS_HIGHLIGHTED_FEATURE_LAYER } from 'shared'

export const image = new CircleStyle({
  radius: 5,
  fill: null,
  stroke: new Stroke({ color: 'red', width: 1 }),
})

export const styles = {
  Point: new Style({
    image,
  }),
  LineString: new Style({
    stroke: new Stroke({
      color: 'green',
      width: 1,
    }),
  }),
  MultiLineString: new Style({
    stroke: new Stroke({
      color: 'green',
      width: 1,
    }),
  }),
  MultiPoint: new Style({
    image,
  }),
  MultiPolygon: new Style({
    stroke: new Stroke({
      color: 'yellow',
      width: 1,
    }),
    fill: new Fill({
      color: 'rgba(255, 255, 0, 0.1)',
    }),
  }),
  Polygon: new Style({
    stroke: new Stroke({
      color: 'blue',
      lineDash: [4],
      width: 3,
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 255, 0.1)',
    }),
  }),
  GeometryCollection: new Style({
    stroke: new Stroke({
      color: 'white',
      width: 4,
    }),
    fill: new Fill({
      color: 'rgba(255, 0, 255, 0.5)', // Magenta color with 50% opacity
    }),
    image: new CircleStyle({
      radius: 8,
      fill: new Fill({
        color: 'rgba(255, 0, 255, 0.5)', // Magenta color with 50% opacity
      }),
      stroke: new Stroke({
        color: 'white',
        width: 4,
      }),
    }),
  }),
  Circle: new Style({
    stroke: new Stroke({
      color: 'red',
      width: 2,
    }),
    fill: new Fill({
      color: 'rgba(255,0,0,0.2)',
    }),
  }),
}

export const styleFunction = function (feature: Feature) {
  return styles[feature.getGeometry().getType()]
}

export function createHighlightedVector({ geojsonObject }) {
  const vectorSource = new VectorSource({
    features: new GeoJSON().readFeatures(geojsonObject),
  })

  const highlitedFeatLayer = new VectorLayer({
    source: vectorSource,
    style: styleFunction,
  })

  highlitedFeatLayer.set('name', WMS_HIGHLIGHTED_FEATURE_LAYER)

  return highlitedFeatLayer
}

export function replaceFeaturesOnVector({
  vector,
  geojsonObject,
}: {
  vector: VectorLayer<VectorSource>
  geojsonObject: any
}) {
  const source: VectorSource = vector.getSource()
  source.clear()
  const geojsonFeatures: Feature<Geometry>[] = new GeoJSON().readFeatures(
    geojsonObject
  )
  source.addFeatures(geojsonFeatures)
  vector.setVisible(true)
  vector.setZIndex(100)
}
