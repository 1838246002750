import { useDispatch, useSelector } from 'react-redux'
import { Assign, Pending, White } from 'icons'
import { Button, Box } from '@material-ui/core'
import { updateOffsetPage, updateSelectedStatusFilter } from 'store/tasks'

import { RootState } from 'store'
import clsx from 'clsx'
import { useStyle } from './TaskStatusFilters.style'

const tasks = (state: RootState) => state.tasks
export const TaskStatusFilters = () => {
  const classes = useStyle()
  const { selectedStatusFilter } = useSelector(tasks)

  const dispatch = useDispatch()

  const handleClick = (status) => {
    dispatch(updateSelectedStatusFilter(status))
    dispatch(updateOffsetPage(0))
  }

  return (
    <Box display="flex" margin="12px 0" style={{ gap: '.5rem' }}>
      <Button
        style={
          selectedStatusFilter === ''
            ? {
                backgroundColor: '#2C2C2C',
                color: 'white',
                fontWeight: 'bold',
              }
            : {}
        }
        variant="outlined"
        onClick={() => handleClick('')}
        className={`${classes.btn} ${
          selectedStatusFilter === '' ? classes.btnAll : ''
        }`}
      >
        الكل
      </Button>
      <Button
        variant="outlined"
        onClick={() => handleClick('assigned')}
        className={clsx(classes.btn, {
          [classes.btnAssignActive]: selectedStatusFilter === 'assigned',
          [classes.btnAssign]: selectedStatusFilter !== 'assigned',
        })}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {selectedStatusFilter === 'assigned' ? (
            <White className={classes.icon} />
          ) : (
            <Assign className={classes.icon} />
          )}
          مسندة
        </Box>
      </Button>
      <Button
        variant="outlined"
        onClick={() => handleClick('pending_review')}
        className={clsx(classes.btn, {
          [classes.btnPendingActive]: selectedStatusFilter === 'pending_review',
          [classes.btnPending]: selectedStatusFilter !== 'pending_review',
        })}
      >
        <Box display="flex" alignItems="center">
          {selectedStatusFilter === 'pending_review' ? (
            <White className={classes.icon} />
          ) : (
            <Pending className={classes.icon} />
          )}
          قيد المراجعة
        </Box>
      </Button>
    </Box>
  )
}
