import React, { ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Drawer } from 'components/Drawer'
import { RootState } from 'store'
import { toggleFormDrawer } from 'store/layout'

export const FormDrawer = ({ form }: { form: ReactNode }) => {
  const dispatch = useDispatch()
  const { isDataLoading, formDrawerOpen } = useSelector(
    (state: RootState) => state.layout
  )

  const handleClose = () => {
    if (!isDataLoading) dispatch(toggleFormDrawer(false))
  }

  return (
    <Drawer
      anchor="bottom"
      onClose={handleClose}
      open={!!formDrawerOpen}
      paperStyles={{ maxHeight: '60vh' }}
    >
      {form}
    </Drawer>
  )
}
