import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from '@flint/locales'
import { toggleLayersDrawerOpen, updateSelectedBaseMap } from 'store/layout'
import { baseMaps } from 'pages/Map/olMapLayers'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { RootState } from 'store'
import { getSelectedBaseMap } from 'utils/general.utils'
import { useStyle } from './Basemaps.style'

export const Basemaps = () => {
  const { selectedBaseMapLabel } = useSelector(
    (state: RootState) => state.layout
  )
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyle()

  return (
    <Accordion className={classes.mapSectionContainer}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-content"
        id="panel-header"
        classes={{
          content: classes.summary,
          root: classes.root,
        }}
      >
        <Typography className={classes.sectionTitle}>
          {t('basemaps')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.mapItemContainer}>
        {baseMaps.map((basemap) => (
          <span
            onClick={() => {
              dispatch(toggleLayersDrawerOpen(false))
              getSelectedBaseMap(selectedBaseMapLabel).map.setVisible(false)
              dispatch(updateSelectedBaseMap(basemap.label))
              basemap.map.setVisible(true)
            }}
            key={basemap.label}
            className={classes.mapSingleItem}
            style={{
              border:
                basemap.label === selectedBaseMapLabel
                  ? '2px solid green'
                  : '1px solid #D3D3D3',
            }}
          >
            <img
              src={basemap.icon}
              alt="map icon"
              width="40px"
              style={{
                padding: basemap.label === 'satellite_google_map' ? 0 : '5px 0',
              }}
            />
            <span>{t(basemap.label)}</span>
          </span>
        ))}
      </AccordionDetails>
    </Accordion>
  )
}
