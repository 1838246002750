import { FormDrawer } from 'components/AppDrawers'
import { LayersControlPanel } from 'components/LayersControlPanel'
import { LocationFab } from 'components/LocationFab'
import { SimpleProjectRecordForm } from 'components/RecordForm'
import BaseMap from 'pages/Map/BaseMap'
import { RasedMap } from 'pages/Map/RasedMap'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { getTaskWmsLayerNames } from 'utils'

export const SimpleProjectMap = () => {
  const mapRef = useRef<RasedMap | null>(null)

  const { selectedListItem } = useSelector((state: RootState) => state.layout)
  const { selectedFeature } = useSelector((state: RootState) => state.tasks)

  useEffect(() => {
    if (selectedFeature) {
      mapRef?.current.handleSelectedFeature(selectedFeature)
    }
  }, [selectedFeature])

  useEffect(() => {
    if (selectedListItem) {
      mapRef?.current.zoomToTaskBoundaries(selectedListItem)

      const taskWmsLayers = getTaskWmsLayerNames(selectedListItem, 'simple')

      // add base wms layer
      mapRef?.current.addWmsLayers(taskWmsLayers.filter((l) => l.type === 'pa'))

      // add clickable wms data layer
      mapRef?.current.addClickableWmsLayer(
        taskWmsLayers.find((l) => l.type === 'points')
      )

      if (selectedListItem?.gpxUrls && selectedListItem?.gpxUrls.length) {
        selectedListItem.gpxUrls?.forEach((url) => {
          mapRef?.current.addGpxLayer(url)
        })
      }
    }

    return () => {
      // reset layers
      mapRef?.current.resetLayers()
    }
  }, [selectedListItem])

  const handleLocationButtonClick = () => {
    if (mapRef.current) {
      mapRef.current.startTracking()
    }
  }

  return (
    <>
      <BaseMap mapRef={mapRef} />
      <LocationFab onClick={handleLocationButtonClick} />
      <div
        style={{
          position: 'absolute',
          top: 60,
          zIndex: 1000,
          right: 1,
          color: '#333',
        }}
      >
        <FormDrawer form={<SimpleProjectRecordForm />} />
        <LayersControlPanel map={mapRef?.current} />
      </div>
    </>
  )
}
