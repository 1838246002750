import { TaskI } from './tasks.interface'

export const updateTaskList = (payload: TaskI[]) => {
  return {
    reducer: 'tasks',
    type: 'taskList',
    payload,
  }
}

export const updateSelectedTask = (payload: TaskI) => ({
  reducer: 'tasks',
  type: 'selectedTask',
  payload,
})

export const updateSelectedFeature = (payload: any) => ({
  reducer: 'tasks',
  type: 'selectedFeature',
  payload,
})

export const updateSelectedStatusFilter = (payload: any) => ({
  reducer: 'tasks',
  type: 'selectedStatusFilter',
  payload,
})

export const updateOffsetPage = (payload: any) => ({
  reducer: 'tasks',
  type: 'offsetPage',
  payload,
})

export const updateSelectedCode = (payload: any) => ({
  reducer: 'tasks',
  type: 'selectedCode',
  payload,
})

export const setSearchQuery = (payload: any) => ({
  reducer: 'tasks',
  type: 'searchQuery',
  payload,
})

export const setTasksCount = (payload: number) => ({
  reducer: 'tasks',
  type: 'tasksCount',
  payload,
})

export const setSelectedRecordSchema = (payload: {
  jsonSchema: any
  webUiJsonSchema: any
}) => ({
  reducer: 'tasks',
  type: 'selectedRecordSchema',
  payload,
})
