import { useLocation as router } from 'react-router-dom'

import { IFlintConfig } from '@flint/types'

import { resources as i18lResources } from './src/global'
import { TatabaAppLogo, TatabaLogo } from './src/icons'
import { plainReducers } from './src/store/states'

export const flintConfig = (): IFlintConfig => ({
  reducers: plainReducers,
  i18l: {
    resources: i18lResources,
  },
  auth: {
    scopes: process.env.REACT_APP_AUTH_SCOPE as string,
    domain: process.env.REACT_APP_AUTH_DOMAIN as string,
    tokenPath: process.env.REACT_APP_AUTH_TOKEN_PATH as string,
    clientId: process.env.REACT_APP_AUTH_WEB_CLIENT_ID as string,
    authorizePath: process.env.REACT_APP_AUTH_AUTHORIZE_PATH as string,
    redirectUri: process.env.REACT_APP_AUTH_WEB_CALLBACK_URL as string,
    revokePath: process.env.REACT_APP_AUTH_REVOKE_PATH as string,
    accountsBaseUrl: process.env.REACT_APP_ACCOUNTS_BASE_URL as string,
  },
  layout: {
    theme: {
      palette: {
        primary: {
          main: '#107543',
        },
        secondary: {
          main: '#3D3D3D',
        },
      },
    },
    drawerToolbar: {
      FullLogo: <TatabaLogo />,
      LogoIcon: <TatabaAppLogo />,
    },
  },
  google: {
    maps: {
      key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    },
  },
  router,
})

export default flintConfig
