import {
  TaskActionProps,
  CreateRecordTaskProps,
  UpdateRecordTaskProps,
  DeleteFieldRecordProps,
  UPLOAD_FILES,
} from 'global'
import {
  FETCH_TASK_LIST,
  CREATE_RECORD_Task,
  CREATE_SIGNED_URL_TASK,
  UPDATE_RECORD_TASK,
  DELETE_FIELD_RECORD,
  CLOSE_TASK,
  REJECT_TASK,
  GET_TASK_CODES,
  FETCH_TASK_Count,
} from 'global/graphql/offers'
import { gqlClient, OrgGqlClient } from 'lib/graphql'

export async function uploadToSignedURL(signedUrl: string, file: File) {
  try {
    const _headers = new Headers()
    _headers.append('Content-Type', 'application/octet-stream')
    const requestOptions = {
      method: 'PUT',
      headers: _headers,
      body: file,
    }

    const response = await fetch(signedUrl, requestOptions as any)
    if (!response.ok) {
      return {
        error: `Failed to upload: ${response.status} ${response.statusText}`,
      }
    }
    return { error: false }
  } catch (error) {
    console.error({ error })
    return { error }
  }
}

export class OffersTaskService {
  async getTaskList(offset, filters = {}) {
    try {
      const { data, loading } = await gqlClient.query({
        query: FETCH_TASK_LIST,
        variables: { offset, filters },
      })

      return { data, loading }
    } catch (error) {
      console.error('Error fetching task list:', error)
      return { data: null, loading: false }
    }
  }

  async getTaskCount(offset, filters = {}) {
    try {
      const { data, loading } = await gqlClient.query({
        query: FETCH_TASK_Count,
        variables: { offset, filters },
      })

      return { data, loading }
    } catch (error) {
      console.error('Error fetching task list:', error)
      return { data: null, loading: false }
    }
  }

  async getTaskCodeList() {
    const { data } = await gqlClient.query({
      query: GET_TASK_CODES,
    })
    return data
  }

  async createSignedURL(fileExtension: string, orderId: number) {
    try {
      const { data, errors } = await gqlClient.mutate({
        mutation: CREATE_SIGNED_URL_TASK as any,
        variables: { orderId, fileExtension },
      })
      const { signedUrl: recordSignedUrl } = data
      const { blobUrl, signedUrl } = recordSignedUrl ?? {}
      return { blobUrl, signedUrl, errors }
    } catch (error) {
      console.error(
        'Backend Error: while trying to generate signed url: ',
        error
      )
      return { blobUrl: null, signedUrl: null, errors: error }
    }
  }

  async uploadFile(file: File | Blob, orgId: number, layerId: number) {
    const { data } = await OrgGqlClient.mutate({
      mutation: UPLOAD_FILES as any,
      variables: {
        context: 'record_field_image',
        extraArgs: JSON.stringify({ layerId, organizationId: orgId }),
        imageFile: file,
      },
    })
    return data.uploadImage.url
  }

  async uploadViaSignedUrl(
    file: File,
    orderId: number,
    organizationId: number,
    fallBack?: (
      file: File,
      organizationId: number,
      layerId: number
    ) => Promise<any>
  ) {
    // request signed url from the backend
    const fileExtension = file.name.split('.').pop()
    const { blobUrl, signedUrl, errors } = await this.createSignedURL(
      fileExtension,
      orderId
    )
    if (errors) {
      console.error('Error obtaining signed url', errors)
      // try to upload via backend server graphql
      if (fallBack) return fallBack(file, organizationId, orderId)
      throw errors
    }
    // upload the actual file to the signed url
    const { error } = await uploadToSignedURL(signedUrl, file)
    if (error) {
      console.error('Error uploading using signed url', error)
      // try to upload via backend server graphql
      if (fallBack) return fallBack(file, organizationId, orderId)
      throw error
    }
    return blobUrl
  }

  async createRecordTask({
    recordData,
    onSuccess,
    onFail,
  }: CreateRecordTaskProps) {
    try {
      const { data } = await gqlClient.mutate({
        mutation: CREATE_RECORD_Task,
        variables: { recordInput: recordData },
      })
      if (data?.rasedUpdateRecord && typeof onSuccess === 'function')
        onSuccess()
    } catch (error) {
      if (onFail) onFail()
      console.error(error)
    }
  }

  async updateRecord({
    recordId,
    taskId,
    formData,
    onSuccess,
    onFail,
  }: UpdateRecordTaskProps) {
    try {
      const { data } = await gqlClient.mutate({
        mutation: UPDATE_RECORD_TASK,
        variables: { recordInput: { recordId, taskId, formData } },
      })
      const { rasedUpdateRecord } = data
      if (data?.rasedUpdateRecord && typeof onSuccess === 'function')
        onSuccess()
      return rasedUpdateRecord
    } catch (error) {
      if (onFail) onFail()
      console.error(error)
    }
  }

  async deleteFieldRecord({
    recordInput,
    onSuccess,
    onFail,
  }: DeleteFieldRecordProps) {
    try {
      await gqlClient.mutate({
        mutation: DELETE_FIELD_RECORD,
        variables: { recordInput },
      })
      if (onSuccess) onSuccess()
    } catch (error) {
      if (onFail) onFail()
      console.error(error)
    }
  }

  async takeAction({ actionType, taskId, onSuccess, onFail }: TaskActionProps) {
    const actionMutations = { close: CLOSE_TASK, reject: REJECT_TASK }
    try {
      await gqlClient.mutate({
        mutation: actionMutations[actionType],
        variables: { taskInput: { id: taskId } },
      })
      if (onSuccess) onSuccess()
    } catch (error) {
      if (onFail) onFail()
      // throw new Error(error)
    }
  }
}
