import { useSelector } from 'react-redux'
import { RootState } from 'store'

import { DrawingToolsBar } from './DrawingToolsBar'

export function DrawingTools({ map }) {
  const {
    drawingTools: { openFlag },
  } = useSelector((state: RootState) => state.layers)

  return openFlag && <DrawingToolsBar map={map} />
}
