import { useState } from 'react'
import toast from 'react-hot-toast'
import Button from '@material-ui/core/Button'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { takeTaskAction } from 'store/tasks/tasks.async'
import { takePoiTaskAction } from 'store/tasks/poi.async'
import { getSelectedProject, isSimpleProject } from 'utils'
import { useUserRole } from 'hooks/useUserRole'
import { UploadFileModal } from 'components/UploadFileModal'
import {
  takeSimpleTaskAction,
  uploadGpxFile,
} from 'store/tasks/simpleProject.async'
import { ConfirmModal } from 'components/ConfirmModal'
import { useStyle } from './TaskStatusButtons.style'

const selectedProject = getSelectedProject()

export const TaskStatusButtons = ({ task }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const classes = useStyle()
  const dispatch = useDispatch()
  const { isSuperUser } = useUserRole()

  const [openModal, setOpenModal] = useState(false)
  const [openUplaodModal, setOpenUploadModal] = useState(false)
  const [actionType, setActionType] = useState(null)
  const [modalContent, setModalContent] = useState('')
  const { selectedListItem } = useSelector((state: RootState) => state.layout)

  const handleOpenMoreActions = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMoreActions = () => {
    setAnchorEl(null)
  }

  const handleCloseTask = (id) => {
    dispatch(
      isSimpleProject
        ? takeSimpleTaskAction({ actionType: 'close', id })
        : selectedProject === 'offers'
        ? takeTaskAction({ actionType: 'close', id })
        : takePoiTaskAction({ actionType: 'close', id })
    )
  }
  const handleRejectTask = (id) => {
    dispatch(
      isSimpleProject
        ? takeSimpleTaskAction({ actionType: 'reject', id })
        : selectedProject === 'offers'
        ? takeTaskAction({ actionType: 'reject', id })
        : takePoiTaskAction({ actionType: 'reject', id })
    )
  }

  const handleConfirm = () => {
    if (isSimpleProject) {
      setOpenUploadModal(false)
    } else {
      setOpenModal(false)
    }
    // Execute the action based on the selected actionType
    if (actionType === 'close') {
      handleCloseTask(task.id)
    } else if (actionType === 'reject') {
      handleRejectTask(task.id)
    }
  }

  const handleUploadGpx = async (gpxUrls) => {
    const existGpxUrls = selectedListItem?.gpxUrls ?? []
    setOpenUploadModal(false)
    const res = await uploadGpxFile({
      taskId: task?.id,
      gpxUrls: JSON.stringify([...gpxUrls, ...existGpxUrls]),
    })
    if (res?.id && res?.gpxUrls) {
      toast.success('تم رفع الملفات بنجاح')
    }
  }

  const openModalWithContent = (type) => {
    setActionType(type)
    // Set modal content based on actionType
    if (type === 'close') {
      setModalContent('Are you sure you want to close this task?')
    } else if (type === 'reject') {
      setModalContent('Are you sure you want to reject this task?')
    }
    setOpenModal(true)
  }

  return (
    <>
      {((task?.status?.hasOwnProperty('assigned') && !isSuperUser) ||
        (!task?.status?.hasOwnProperty('assigned') && isSuperUser)) && (
        <Button
          className={classes.endBtn}
          onClick={() => openModalWithContent('close')}
        >
          إنهاء المهمة
        </Button>
      )}

      {isSuperUser && !task?.status?.hasOwnProperty('assigned') && (
        <Button
          className={classes.cancelBtn}
          onClick={() => openModalWithContent('reject')}
        >
          رفض
        </Button>
      )}
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleOpenMoreActions}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMoreActions}
      >
        <MenuItem
          onClick={() => {
            setOpenUploadModal(true)
            handleCloseMoreActions()
          }}
        >
          ارفع ملفات gpx
        </MenuItem>
      </Menu>

      <ConfirmModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onConfirm={handleConfirm}
        content={modalContent}
      />
      <UploadFileModal
        open={openUplaodModal}
        onConfirmCb={handleUploadGpx}
        onClose={() => setOpenUploadModal(false)}
        taskId={task?.id}
      />
    </>
  )
}
