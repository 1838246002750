import { useEffect, useRef } from 'react'
import { Map } from 'ol'
import { Draw, Modify, Snap, Translate } from 'ol/interaction'
import WKT from 'ol/format/WKT'
import { Vector as VectorLayer } from 'ol/layer'
import { Vector as VectorSource } from 'ol/source'
import { DrawEvent } from 'ol/interaction/Draw'

type GeometryType = 'Point' | 'LineString' | 'Polygon'

export function useDrawingTools(
  map: Map,
  onSave: (wkt: string) => void,
  onDrawEnd: (evt: DrawEvent, draw: Draw) => void
) {
  const source = useRef(new VectorSource())
  const vector = useRef(new VectorLayer({ source: source.current }))
  const interactions = useRef({
    modify: null,
    draw: null,
    snap: null,
    translate: null,
  })

  const createInteractions = ({ geomType, source }) => {
    const modify = new Modify({ source })
    const draw = new Draw({ source, type: geomType })
    const snap = new Snap({ source })
    const translate = new Translate({ layers: [vector.current] })

    return { modify, draw, snap, translate }
  }

  const addInteractions = ({ modify, draw, snap, translate }) => {
    map.addInteraction(modify)
    map.addInteraction(draw)
    map.addInteraction(snap)
    map.addInteraction(translate)
  }

  const removeMapInteractions = ({ map, draw, snap, translate }) => {
    map.removeInteraction(draw)
    map.removeInteraction(snap)
    map.removeInteraction(translate)
  }

  const createCurrentInteractions = (geomType: GeometryType = 'Point') => {
    interactions.current = createInteractions({
      geomType,
      source: source.current,
    })
    const { modify, draw, snap, translate } = interactions.current
    addInteractions({
      modify,
      draw,
      snap,
      translate,
    })
    map.addLayer(vector.current)
    draw.on('drawend', (evt: DrawEvent) => onDrawEnd(evt, draw))
  }

  const removeCurrentInteractions = ({ isClear = true } = {}) => {
    if (!interactions.current) return
    // remove interactions
    const { draw, snap, translate } = interactions.current
    removeMapInteractions({ map, draw, snap, translate })
    // clear the ref
    interactions.current = null
    // remove map layer
    map.removeLayer(vector.current)
    // remove current features
    if (isClear) source.current.clear()
  }

  useEffect(() => {
    createCurrentInteractions()

    return () => {
      removeCurrentInteractions()
    }
  }, [])

  const handleGeomTypeChange = (geomType: GeometryType) => {
    removeCurrentInteractions({ isClear: false })
    createCurrentInteractions(geomType)
  }

  const handleUndoFunction = () => {
    if (!interactions.current) return
    const { draw } = interactions.current
    draw.removeLastPoint()
  }

  const handleSaveFunction = () => {
    const features = source.current.getFeatures()
    if (features.length === 0 || features.length > 1) {
      console.warn('invalid number of features', features.length)
      return
    }
    const wktFeature = new WKT().writeFeature(features[0], {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857',
    })
    if (typeof onSave === 'function') onSave(wktFeature)
  }

  const handleDeleteFunction = (geomType: GeometryType) => {
    removeCurrentInteractions()
    createCurrentInteractions(geomType)
  }

  return {
    handleGeomTypeChange,
    handleUndoFunction,
    handleSaveFunction,
    handleDeleteFunction,
  }
}
