import React from 'react'
import Fab from '@material-ui/core/Fab'

import { useStyle } from './MapFab.style'

export const MapFab = ({ icon, onClick }) => {
  const styles = useStyle()
  return (
    <Fab
      className={styles.root}
      color="primary"
      aria-label="add"
      onClick={onClick}
    >
      {icon}
    </Fab>
  )
}
