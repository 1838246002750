import { ApolloProvider } from '@apollo/client'
import { getCurrentGql } from 'utils/common'
import { createGqlClient } from './graphql'

export const gqlClient = createGqlClient(getCurrentGql())
export const generalGqlClient = createGqlClient(
  process.env.REACT_APP_GENERAL_GQL
)

export const OrgGqlClient = createGqlClient(
  process.env.REACT_APP_ORGANIZATION_GQL
)

export const DynamicGqlProvider = ({ children }: { children: any }) => {
  return <ApolloProvider client={gqlClient}>{children}</ApolloProvider>
}
