import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from '@flint/locales'
import { useSelector, useDispatch } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
import { RootState } from 'store'
import { projectsData } from 'shared/data'
import { fetchGlobalTaskCount } from 'store/layout/layout.async'
import { generalGqlClient } from 'lib/graphql'
import { vdIcon } from 'icons'
import { GET_PROJECT_LIST } from 'global'
import { useStyles } from './ProjectSwitcherList.style'

const handleProjectSelection = (projectId: string, isSimple = false) => {
  const projectRoute = isSimple
    ? `../simple-project/${projectId}`
    : `/${projectId}`
  localStorage.setItem('selected-gql', projectId)
  window.location.href = projectRoute
}

export const ProjectSwitcherList = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [allProjects, setAllProjects] = useState<any>([...projectsData])
  const layout = (state: RootState) => state.layout
  const dispatch = useDispatch()

  const { globalTaskCount, countLoading } = useSelector(layout)

  const formatTaskCountDescription = (count: number): string => {
    if (count === 0) return 'لا يوجد مهام حالياً'
    if (count > 0) return `لديك ${count} مهام في هذا المشروع`
  }
  useEffect(() => {
    dispatch(fetchGlobalTaskCount())
  }, [])

  useEffect(() => {
    const fetchSimpleProjects = async () => {
      const { data } = await generalGqlClient.query({
        query: GET_PROJECT_LIST,
      })
      const simpleProjects = data?.projects
        .filter((p) => !['offers', 'poi'].includes(p.code))
        .map((p) => ({
          id: p?.id,
          title: p?.name,
          code: p?.code,
          icon: vdIcon,
          isSimple: true,
        }))
      setAllProjects((v) => [...v, ...simpleProjects])
    }
    fetchSimpleProjects()
  }, [])

  return (
    <Box>
      <Typography style={{ fontWeight: 'bold', marginBottom: '1rem' }}>
        {t('Please choose a project')}
      </Typography>
      <Card className={classes.projectsCard}>
        {allProjects.map(({ id, icon: Icon, title, isSimple }) => (
          <Button
            onClick={() => handleProjectSelection(id, isSimple)}
            key={id}
            fullWidth
            className={classes.projectRow}
            style={{ borderBottom: `${id === 'poi' ? 0 : ''}` }}
          >
            <Icon style={{ width: '50px', marginLeft: 15 }} />
            <Box className={classes.titleCountwraperr}>
              <Typography style={{ fontWeight: 'bold' }}>{title}</Typography>
              {countLoading ? (
                <Skeleton
                  animation="wave"
                  height={10}
                  width="11.6rem"
                  style={{ marginBlock: 6 }}
                />
              ) : (
                <Typography variant="caption" className={classes.taskCountText}>
                  {formatTaskCountDescription(globalTaskCount[id])}
                </Typography>
              )}
            </Box>
          </Button>
        ))}
      </Card>
    </Box>
  )
}
