import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Checkbox, Divider } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { getReplacedName } from 'utils/simple.utils'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useStyle } from './LayerGroupVisibilityToggle.style'

export const LayerGroupVisibilityToggle = ({ map }) => {
  const classes = useStyle()
  const [layers, setLayers] = useState<any[]>([])
  const { selectedListItem } = useSelector((state: RootState) => state.layout)
  const [gpxVisibility, setGpxVisibility] = useState<boolean>(true)

  useEffect(() => {
    if (map) {
      const initialLayers = map
        .getLayers()
        .getArray()
        .filter((layer) => layer?.get('toggleable'))
      setLayers(initialLayers)
    }
  }, [map, selectedListItem])

  const toggleLayerVisibility = (layerId: string) => {
    setLayers(
      layers.map((layer) => {
        if (layer.get('id') === layerId) {
          layer.setVisible(!layer.getVisible())
        }
        return layer
      })
    )
  }

  const toggleGpxVisibility = () => {
    const newVisibility = !gpxVisibility
    layers.forEach((layer) => {
      if (layer.get('name').includes('gpx')) {
        layer.setVisible(newVisibility)
      }
    })
    setGpxVisibility(newVisibility)
  }

  return (
    <div className={classes.layerList}>
      {layers
        .filter((layer) => !layer.get('name').includes('gpx'))
        .map((layer, i, arr) => (
          <div key={layer.get('name')}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={layer.getVisible()}
                  onChange={() => toggleLayerVisibility(layer.get('id'))}
                  name="layer item"
                  color="primary"
                />
              }
              label={getReplacedName(layer.get('name'))}
              classes={{ label: classes.label }}
            />
            {i < arr.length - 1 && <Divider />}
          </div>
        ))}
      {selectedListItem?.gpxUrls?.length > 0 ? (
        <>
          <Divider />{' '}
          <FormControlLabel
            control={
              <Checkbox
                checked={gpxVisibility}
                onChange={toggleGpxVisibility}
                name="gpx layers"
                color="primary"
              />
            }
            label={getReplacedName('gpx')}
            classes={{ label: classes.label }}
          />
        </>
      ) : (
        ''
      )}
    </div>
  )
}
