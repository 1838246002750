import { createUploadLink } from 'apollo-upload-client'
import { ApolloClient, InMemoryCache, from } from '@apollo/client'
import { customHttpFetch, networkErrorLink } from '@flint/graphql'
import { authLink } from './links'

export const createGqlClient = (endpoint: string) => {
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: from([
      authLink,
      networkErrorLink,
      createUploadLink({
        uri: endpoint,
        fetch: customHttpFetch,
      }),
    ]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  })
}
