import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleLayersDrawerOpen } from 'store/layout'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'

import Toolbar from '@material-ui/core/Toolbar'
import MenuIcon from '@material-ui/icons/Menu'
import LinearProgress from '@material-ui/core/LinearProgress'
import { RootState } from 'store'
import { RasedLogo } from 'icons'
import {
  ProjectSwitcherHeader,
  ProjectSwitcherList,
} from 'components/ProjectSwitcher'
import { ProjectSwitcherDrawer } from 'components/AppDrawers/ProjectSwitcherDrawer'
import { UserSection } from './UserSection'
import { useStyles } from './TopBar.style'

export function TopBar() {
  const classes = useStyles()

  const dispatch = useDispatch()
  const { isDataLoading } = useSelector(
    (rootState: RootState) => rootState.layout
  )

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar className={classes.toolbarContainer}>
          <div className={classes.logoContainer}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={() => {
                dispatch(toggleLayersDrawerOpen(true))
              }}
            >
              <MenuIcon />
            </IconButton>
            <RasedLogo />
          </div>
          <UserSection />
        </Toolbar>
        {isDataLoading && <LinearProgress />}
      </AppBar>
      <ProjectSwitcherDrawer>
        <ProjectSwitcherHeader />
        <ProjectSwitcherList />
      </ProjectSwitcherDrawer>
    </div>
  )
}
