import Interaction from 'ol/interaction/Interaction'
import { getFullToken } from '@flint/auth'

class WMSClick extends Interaction {
  constructor(
    openFlag,
    source,
    organizationId,
    setLoading,
    processData,
    processError
  ) {
    super({
      handleEvent(evt) {
        if (evt.type === 'singleclick') {
          // if the drawing tools are open
          if (openFlag) return
          setLoading()
          // fetch feature info from geoserver
          const view = evt.map.getView()
          const viewResolution = view.getResolution()

          const url = source.getFeatureInfoUrl(
            evt.coordinate,
            viewResolution,
            view.getProjection(),
            {
              INFO_FORMAT: 'application/json',
              FEATURE_COUNT: 50,
              ORGANIZATION: organizationId,
              TIME: new Date().toISOString(),
            }
          )

          const requestHeaders = new Headers()
          requestHeaders.append('Authorization', getFullToken())
          fetch(url, { headers: requestHeaders, method: 'GET', mode: 'cors' })
            .then((response) => response.json())
            .then((data) => {
              // callback to handle the data
              processData(data)
            })
            .catch((error) => {
              // callback to handle the error
              processError(error)
            })
        }
        return true
      },
    })
  }
}

export { WMSClick }
