import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(() => ({
  drawerContentWrapper: {
    maxHeight: '50vh',
  },
  addBtn: {
    width: '160px',
    margin: '10px 5px',
  },
  actionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'fit-content',
  },
}))
