import { ListItemContent, LoadingIndicator } from 'components'
import { ListItem, Typography } from '@material-ui/core'
import { useTranslation } from '@flint/locales'
import { useStyle } from './List.style'

export const LayersList = ({ layers }) => {
  const classes = useStyle()
  const { t } = useTranslation()
  return (
    <>
      <Typography className={classes.title} component="h5">
        {t('layers')}
      </Typography>
      <div className={classes.layersContainer}>
        <LoadingIndicator />
        <div>
          {layers.map((layer) => (
            <ListItem className={classes.layerItem} button key={layer.id}>
              <ListItemContent item={layer} title="layer" />
            </ListItem>
          ))}
        </div>
      </div>
    </>
  )
}
