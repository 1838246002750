import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { Button, Divider } from '@material-ui/core'
import validator from '@rjsf/validator-ajv8'
import { useTranslation } from '@flint/locales'
import { ReactJsonSchemaForm } from '@flint/rjsf'
import { LayerServiceInstance } from 'services/layers.services'
import { RootState } from 'store'
import { ORGANIZATION_ID } from 'shared'
import { resetWMSLayers, updateDrawingTools } from 'store/layers'
import { toggleDataLoading, toggleFormDrawer } from 'store/layout'
import { isEmptyObject } from 'utils/general.utils'
import { useStyle } from './RecordForm.style'

export function permissionExist(
  permissions: string[],
  permission: string
): boolean {
  if (permissions.length < 1) return false
  return permissions.includes(permission)
}

export function LayerRecordForm() {
  const { t } = useTranslation()
  const classes = useStyle()
  const dispatch = useDispatch()
  // const submitButtonRef = createRef<any>()

  const { selectedRecord, resetWMSFlag, drawingTools } = useSelector(
    (rootState: RootState) => rootState.layers
  )
  const { selectedListItem, isDataLoading } = useSelector(
    (rootState: RootState) => rootState.layout
  )

  const { jsonSchema, webUiJsonSchema, userPermissions } =
    selectedListItem || {}
  const hasEditRecordPermission = permissionExist(
    userPermissions,
    'edit_record'
  )
  const formDisabled = !hasEditRecordPermission || isDataLoading
  const [formData, setFormData] = useState(selectedRecord?.data)
  const [hiddenForm, setHiddenForm] = useState(false)

  const addNewRecord = () => {
    LayerServiceInstance.createRecord({
      layer: selectedListItem?.id,
      geometry: selectedRecord.wkt,
      data: JSON.stringify(formData || {}),
    })
    // close the drawing tools bar
    dispatch(updateDrawingTools({ ...drawingTools, openFlag: false }))
  }
  const updateExisting = () => {
    LayerServiceInstance.updateRecord({
      id: selectedRecord.id,
      data: JSON.stringify(formData || {}),
    })
  }
  const onSubmit = () => {
    dispatch(toggleDataLoading(true))
    if (selectedRecord.wkt) addNewRecord()
    else updateExisting()
    dispatch(resetWMSLayers(!resetWMSFlag))
    dispatch(toggleDataLoading(false))
    dispatch(toggleFormDrawer(false))
  }
  // const onSave = () => {
  //   submitButtonRef.current.click()
  // }
  const forceResetRerender = () => {
    const p = new Promise((resolve) => {
      setHiddenForm(true)
      setFormData({})
      resolve(true)
    })
    p.then(() => {
      setHiddenForm(false)
    })
  }
  const onCancel = () => {
    if (isDataLoading) return
    dispatch(toggleFormDrawer(false))
    forceResetRerender()
  }
  const onError = (error: any) => {
    console.error('form error', error)
  }
  const onFileChange = async (data: Array<{ file: File }> | { file: File }) => {
    let response: any
    dispatch(toggleDataLoading(true))
    try {
      if (Array.isArray(data)) {
        const promises = data.map(({ file }) =>
          LayerServiceInstance.uploadViaSignedUrl(
            file,
            ORGANIZATION_ID,
            Number(selectedListItem.id),
            LayerServiceInstance.uploadFile
          )
        )
        response = await Promise.all(promises)
      } else {
        response = await LayerServiceInstance.uploadViaSignedUrl(
          data.file,
          ORGANIZATION_ID,
          Number(selectedListItem.id),
          LayerServiceInstance.uploadFile
        )
      }
    } catch (error) {
      console.error(error)
      dispatch(toggleDataLoading(false))
      return
    }
    dispatch(toggleDataLoading(false))
    return response
  }

  // force reset the whole rjsf component
  useEffect(forceResetRerender, [])

  // update form data from redux store
  useEffect(() => {
    if (!selectedRecord) dispatch(toggleFormDrawer(false))
    setFormData(selectedRecord?.data)
  }, [selectedRecord?.id])

  // eslint-disable-next-line
  if (!jsonSchema || isEmptyObject(jsonSchema)) return <Fragment />

  return (
    <div className={classes.entrySection}>
      {!hasEditRecordPermission && (
        <Typography variant="body2" className={classes.dangerText}>
          {t('note')}:{t('record_edit_permission_error')}
        </Typography>
      )}
      <div className={classes.entrySectionFormSchemaContainer}>
        {!hiddenForm && (
          <ReactJsonSchemaForm
            validator={validator}
            onSubmit={onSubmit}
            onError={onError}
            schema={jsonSchema as any}
            uiSchema={webUiJsonSchema as any}
            formData={formData}
            setFormData={setFormData}
            experimental_defaultFormStateBehavior={{
              allOf: 'populateDefaults',
            }}
            liveValidate={false}
            showErrorList={false}
            disabled={formDisabled}
            formContext={{ onFileChange }}
            omitExtraData
            liveOmit
            noHtml5Validate
          >
            <Divider />
            {/* <input ref={submitButtonRef as any} type="submit" value="" hidden /> */}
            <div className={classes.actionsContainer}>
              {/* <input type="submit" hidden /> */}
              <Button
                className={classes.actionButton}
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                disableElevation
                disabled={formDisabled}
                // onClick={onSave}
                type="submit"
              >
                {t('save')}
              </Button>
              <Button
                onClick={onCancel}
                className={classes.actionButton}
                variant="contained"
                color="default"
                size="small"
                type="reset"
                fullWidth
                disableElevation
                disabled={isDataLoading}
              >
                {t('cancel')}
              </Button>
            </div>
          </ReactJsonSchemaForm>
        )}
      </div>
    </div>
  )
}
