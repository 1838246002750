import { gql } from '@apollo/client'

export const FETCH_POI_TASKS = gql`
  query FetchPoiTasks($offset: BoundedInt, $filters: [DjangoFilterInput!]) {
    rasedTasks(
      pageInfo: { limit: 10, offset: $offset, orderBy: "id" }
      filters: $filters
    ) {
      data {
        code
        created
        modified
        id
        paWmsName
        parcelsWmsName
        pointsWmsName
        status
        boundaries
        teamCode
      }
      count
    }
  }
`
export const GET_POI_RECORD = gql`
  query GetReacordData($taskId: Int!, $pk: Int) {
    records(taskId: $taskId, pk: $pk) {
      data {
        sourceProperties {
          poiType
          locationType
        }
        id
        data
        geometry
        mapData
      }
    }
  }
`

export const POI_FORM_SCHEMAS = gql`
  query fromSchemas {
    orders {
      data {
        jsonSchema
        id
      }
    }
  }
`
export const FETCH_TASK_Count = gql`
  query FetchTaskCount($offset: BoundedInt, $filters: [DjangoFilterInput!]) {
    rasedTasks(
      pageInfo: { limit: 10, offset: $offset, orderBy: "id" }
      filters: $filters
    ) {
      count
    }
  }
`

export const GET_TASK_CODES = gql`
  query FetchTaskList {
    rasedTasks {
      data {
        code
      }
    }
  }
`
