import { Box, Typography } from '@material-ui/core'
import { useTranslation } from '@flint/locales'
import { useUserRole } from 'hooks/useUserRole'
import { TaskSearchInput } from 'components/TaskSearchInput'
import { TaskStatusFilters } from 'components/TaskStatusFilters'
import { useStyle } from './HeaderSideList.style'

export const HeaderSideList = () => {
  const { t } = useTranslation()
  const classes = useStyle()
  const { isSuperUser } = useUserRole()

  return (
    <Box className={classes.sectionTitle}>
      <Typography component="h5" className={classes.title}>
        {t('tasks')}
      </Typography>
      {isSuperUser && <TaskStatusFilters />}
      <TaskSearchInput />
    </Box>
  )
}
