import { offersIcon, poiIcon, motIcon } from 'icons'
import React from 'react'
import { ProjectI } from 'store/tasks/tasks.interface'

export const projectsData: {
  id: ProjectI
  title: string
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}[] = [
  {
    id: 'vd',
    title: 'مشروع وزارة السياحة',
    icon: motIcon,
  },
  {
    id: 'offers',
    title: 'مشروع جلب العروض',
    icon: offersIcon,
  },
  {
    id: 'poi',
    title: 'مشروع  المعالم',
    icon: poiIcon,
  },
]
