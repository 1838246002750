import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: '#777',
    fontWeight: 'bold',
    fontSize: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
}))
